import React, { Component } from "react";
import { Row, Col, Space, Modal,  Form, DatePicker, Input, InputNumber, Select, message, Spin, Divider, Button, Tag, } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import { Uploader } from "../../../Widgets/Uploaders";
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import { SelectProveedor } from "../../../Widgets/Inputs/Selects"

const { Option } = Select


const axios = require('axios').default;
const moment = require('moment');

class ModalTransaccionProgramada extends Component {


    static defaultProps = {
        area: false,
        propiedad: false
    }

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            areas: {
                data: [],

                page: 1,
                limit: 0,

                search: null
            },
            rubros: {
                data: [],

                page: 1,
                limit: 15,

                search: null
            },
            proyectos: {
                data: [],

                page: 1,
                limit: 10,

                pages: 0,
                total: 0,
                search: null
            },
            cuentas: {
                data: [],

                page: 1,
                limit: 15,

                search: null
            },
            transacciones: {
                data: [],

                skip: 0,
                limit: 15,
                total: 0,

                search: null
            },
            has_pagos: false,
            filters: {},
            cuentaSeleccionada: '',
            disabled: false,
            cliente_id: undefined,
            dias_limite: 7,
            abonos: []
        }
    }

    modalRef = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getAreas();
        this.getProyectos();


        this.modalRef.current.setFieldsValue({
            dias_limite: 7
        })
        if (this.props.transaccion_programada_id) {
            this.getTransaccionProgramada();
        } else {
            if (this.props.propiedad_id) { this.getPropiedad() }
            if (this.props.inversion_id) { this.getInversion() }
            if (this.props.acreedor_id) { this.getAcreedor() }
        }

    }


    /**
     * @memberof ModalTransaccionProgramada
     * @method getTransaccionProgramada
     * @description 
     * 
     */
    getTransaccionProgramada = () => {
        this.setState({ loading: true })

        axios.get(`/transacciones-programadas/${this.props.transaccion_programada_id}`, {
            params: {
                id: this.props.transaccion_programada_id
            }
        })
            .then(({ data }) => {
                console.log(" X ", data.conceptos)

                let tProgramada = {
                    ...data,
                    fecha_inicio_limite: undefined,
                    fecha_limite: undefined,
                    fecha_inicio: undefined,
                    cuenta_id: data?.cuenta_id?._id,
                    area_id: {
                        value: data?.area_id?._id,
                        label: data?.area_id?.nombre
                    },
                    rubro_id: {
                        value: data?.rubro_id?._id,
                        label: data?.rubro_id?.nombre
                    },
                    proyecto_id: {
                        value: data?.proyecto_id?._id,
                        label: data?.proyecto_id?.nombre
                    },
                    inversion_id: {
                        value: data?.inversion_id?._id,
                        label: data?.inversion_id?.nombre
                    },
                    acreedor_id: {
                        value: data?.acreedor_id?._id,
                        label: data?.acreedor_id?.acreedor
                    },
                    propiedad_id: {
                        value: data?.propiedad_id?._id,
                        label: data?.propiedad_id?.nombre
                    },
                    cliente_id: data?.cliente_id?._id ? {
                        value: data?.cliente_id?._id,
                        label: data?.cliente_id?.nombre
                    } : null,
                    proveedor_id: data?.proveedor_id?._id ? {
                        value: data?.proveedor_id?._id,
                        label: data?.proveedor_id?.alias
                    } : null,
                    comprobantes: data.comprobantes.map((file, index) => ({
                        uid: file.file,
                        name: file.name,
                        status: 'done',
                        fromDB: true,
                        url: axios.defaults.baseURL + "/transacciones-programadas/" + this.props.transaccion_programada_id + "?comprobante=" + file.file + "&Authorization=" + sessionStorage.getItem("token")
                    })),

                }
                // if (data.periodo != 1)
                //     tProgramada.fecha_inicio_limite = [moment(data.fecha_inicio), moment(data.fecha_limite)]
                // else
                tProgramada.fecha_limite = moment(data.fecha_limite)

                this.modalRef.current?.setFieldsValue(tProgramada)
                this.setState({
                    loading: false,
                    periodo: tProgramada.periodo,
                    tipo: tProgramada.tipo,
                })
                // this.getAbonos({}, tProgramada)
            })
            .catch(error => {

                console.log('error al traer transaccon', error)
                this.setState({ loading: false })

            }).finally(() => {

                this.setState({ loading: false, disabled: true })
            })
    }


    getPagos = ({
        skip = this.state.pagos.skip,
        total = this.state.pagos.total,
        search = this.state.pagos.search,
    } = this.state.transacciones, { transaccion_programada_id } = this.props) => {
        axios.get('/transacciones-programadas/pagos', {
            params: {
                skip,
                search,
                transaccion_programada_id
            }
        })
            .then(({ data }) => {
                this.setState({
                    pagos: { ...data, loading: false }
                })
            })
            .catch(error => {
                console.log(error)
                message.error('Error al traer los pagos')
            })
    }

    /**
    * @memberof ModalTransaccionProgramada
    * @method getPropiedad
    * @description Trae los datos de la propiedad y los carga en el formulario 
    * 
    */
    getPropiedad = ({
        propiedad_id = this.props.propiedad_id
    } = this.state) => {
        this.setState({ loading: true })

        axios.get('/propiedades/' + propiedad_id)
            .then(({ data }) => {
                const { area_id, rubro_id, cliente_id, proyecto_id, } = data;

                let values = {
                    propiedad_id: {
                        value: data?._id,
                        key: data?._id,
                        label: data?.inmueble_id?.nombre,
                    }
                }

                if (typeof area_id == "object")
                    values['area_id'] = {
                        value: area_id?._id,
                        key: area_id?._id,
                        label: area_id?.nombre,
                    }

                if (typeof rubro_id == "object")
                    values['rubro_id'] = {
                        value: rubro_id?._id,
                        key: rubro_id?._id,
                        label: rubro_id?.nombre,
                    }

                if (typeof cliente_id == "object")
                    values['cliente_id'] = {
                        value: cliente_id?._id,
                        key: cliente_id?._id,
                        label: cliente_id?.nombre,
                    }

                if (typeof proyecto_id == "object")
                    values['proyecto_id'] = {
                        value: proyecto_id?._id,
                        key: proyecto_id?._id,
                        label: proyecto_id?.nombre,
                    }

                console.log("values", values)
                this.modalRef.current.setFieldsValue(values)
            })
            .catch(error => {
                console.log(error)
                message.error('Error al traer la propiedad')
            })
            .finally(() => this.setState({ loading: false }))

    }

    /**
   * @memberof ModalTransaccionProgramada
   * @method getInversion
   * @description Trae los datos de la inversion y los carga en el formulario 
   * 
   */
    getInversion = ({
        inversion_id = this.props.inversion_id
    } = this.state) => {
        this.setState({ loading: true })

        axios.get('/inversiones/get', { params: { _id: inversion_id } })
            .then(({ data }) => {
                const { area_id, rubro_id, proyecto_id, ...inversion } = data.data.inversion;

                let values = {
                    inversion_id: {
                        value: inversion?._id,
                        key: inversion?._id,
                        label: inversion?.nombre,
                    }
                }

                if (typeof area_id == "object")
                    values['area_id'] = {
                        value: area_id?._id,
                        key: area_id?._id,
                        label: area_id?.nombre,
                    }

                if (typeof rubro_id == "object")
                    values['rubro_id'] = {
                        value: rubro_id?._id,
                        key: rubro_id?._id,
                        label: rubro_id?.nombre,
                    }


                if (typeof proyecto_id == "object")
                    values['proyecto_id'] = {
                        value: proyecto_id?._id,
                        key: proyecto_id?._id,
                        label: proyecto_id?.nombre,
                    }

                console.log("values", values)
                this.modalRef.current.setFieldsValue(values)
            })
            .catch(error => {
                console.log(error)
                message.error('Error al traer la cliente')
            })
            .finally(() => this.setState({ loading: false }))

    }

    /**
   * @memberof ModalTransaccionProgramada
   * @method getAcreedor
   * @description Trae los datos del acreedor y los carga en el formulario 
   * 
   */
    getAcreedor = ({
        acreedor_id = this.props.acreedor_id
    } = this.state) => {
        this.setState({ loading: true })

        axios.get('/acreedores/' + acreedor_id, { params: { id: acreedor_id } })
            .then(({ data }) => {
                const { area_id, rubro_id, proyecto_id } = data;

                let values = {
                    acreedor_id: {
                        value: data?._id,
                        key: data?._id,
                        label: data?.acreedor,
                    }
                }

                if (typeof area_id == "object")
                    values['area_id'] = {
                        value: area_id?._id,
                        key: area_id?._id,
                        label: area_id?.nombre,
                    }

                if (typeof rubro_id == "object")
                    values['rubro_id'] = {
                        value: rubro_id?._id,
                        key: rubro_id?._id,
                        label: rubro_id?.nombre,
                    }

                if (typeof proyecto_id == "object")
                    values['proyecto_id'] = {
                        value: proyecto_id?._id,
                        key: proyecto_id?._id,
                        label: proyecto_id?.nombre,
                    }

                console.log("values", values)
                this.modalRef.current.setFieldsValue(values)
            })
            .catch(error => {
                console.log(error)
                message.error('Error al traer la cliente')
            })
            .finally(() => this.setState({ loading: false }))

    }


    /**
    * @memberof ModalTransaccionProgramada
    * @method getAreas
    * @description Trae las areas 
    * 
    */
    getAreas = ({

        page = this.state.areas.page,
        limit = this.state.areas.limit,
        search = this.state.areas.search

    } = this.state.areas) => {

        this.setState({ areas: { ...this.state.areas, loading: true, page, limit, search } })
        axios.get('/areas', {
            params: {
                page,
                limit,
                search

            }
        }).then(({ data }) => {
            this.setState({
                areas: data,

            });
        }).catch(error => {
            console.log(error)
            message.error('Error al traer las areas')
        })
    }

    /**
     * @memberof ModalTransaccionProgramada
     * @method loadRubros
     * @description Trae los rubros asiganos a una area y los formatea para el cascader
     * 
     */
    loadRubros = ({
        page = this.state.rubros.page,
        limit = this.state.rubros.limit,
        search = this.state.rubros.search,
        area_id = this.state.filters.area_id,

    } = this.state.rubros) => {
        this.setState({
            rubros: { ...this.state.rubros, loading: true, page, limit, search },
            filters: { ...this.state.filters, area_id },
        })
        axios.get('/rubros', {
            params: {
                search,
                page,
                limit,
                area_id
            }
        }).then(({ data }) => {
            this.setState({
                rubros: data,
            });
        }).catch(error => {
            console.log(error)
            message.error('Error al cargar los rubros')
        })

    }


    /**
    *
    * @memberof ModalTransacciones
    * @method getProyectos
    * @description Trae los proyectos
    */
    getProyectos = ({

        page = this.state.proyectos.page,
        limit = this.state.proyectos.limit,
        search = this.state.proyectos.search

    } = this.state.proyectos) => {

        this.setState({ proyectos: { ...this.state.proyectos, page, limit }, loading: true })
        axios.get('/proyectos', {
            params: { limit, page, search }
        })
            .then(({ data }) => {
                this.setState({ proyectos: { ...this.state.proyectos, ...data } })
            })
            .catch(error => {
                console.log('error', error)
                message.error('No se pudieron cargar los proyectos')
            })
            .finally(() => this.setState({ loading: false }))
    }

    /**
    * @memberof ModalTransaccionProgramada
    * @method add
    * @description Programa una nueva transaccion
    * 
    */
    add = (values) => {
        this.setState({ loading: true })
        axios.post('/transacciones-programadas', values)
            .then(response => {
                message.success('Transacción programada')
                this.props.onClose(true)
            }).catch(error => {
                console.log(error)
                message.error('Error al programar la transaccion')
            }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @memberof ModalTransaccionProgramada
    * @method update
    * @description Actualiza la transaccion
    * 
    */
    update = (values) => {
        this.setState({ loading: true })
        axios.put('/transacciones-programadas', values)
            .then(response => {
                message.success('Transacción Programada Actualizada')
                this.props.onClose(true)
            }).catch(error => {
                console.log(error)
                message.error('Error al actualizar la transaccion programada')
            }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof ModalTransaccionProgramada
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    onFinish = (values) => {
        for (const key in values)
            if (values[key]?.value)
                values[key] = values[key]?.value

        const formData = new FormData()
        formData.appendMultiple({
            ...values,
            id: this.props.transaccion_programada_id,
            comprobantes: undefined
        })

        values.comprobantes?.forEach(e => {
            if (!e.fromDB) formData.append("comprobantes", e, e.name)
        })

        formData.append("abonos", JSON.stringify(this.state.abonos))

        if (this.props.transaccion_programada_id)
            this.update(formData)
        else
            this.add(formData)
    }




    renderEstatus = (estatus) => {
        switch (estatus) {
            case 0:
                return <Tag color="#F50">Vencida</Tag>
            case 1:
                return <Tag color="#FBBC30">Proxima</Tag>
            case 2:
                return <Tag color="#87d068">Pendiente</Tag>
        }
    }


    updateIncremental = value => {
        this.setState({ autoincremental: value?.target?.checked })
        this.modalRef.current.setFieldValue({ concepto: "Pago *numero#, del mes de *mes* " })
    }


    render() {

        const {
            area,
            // Ids
            propiedad_id,
            inversion_id,
            acreedor_id,
            proveedor_id

        } = this.props;
        const {
            filters,
            has_pagos
        } = this.state


        return (
            <Form
                layout="vertical"
                name="form-transaccion"
                id="form-transaccion"
                ref={this.modalRef}
                onFinish={this.onFinish}
                onValuesChange={this.getAbonos}
                initialValues={{
                    ...this.state.initialValues,
                    proveedor_id: proveedor_id ?? null,
                }}
            >
                <Spin spinning={this.state.loading}>
                    <Row gutter={[16, 0]}>
                        {
                            this.props.transaccion_programada_id && (
                                <Col xs={24}>
                                    <Form.Item
                                        label="Folio"
                                        name="folio"
                                    >
                                        <Input placeholder="Folio" disabled></Input>
                                    </Form.Item>
                                </Col>
                            )
                        }
                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Concepto"
                                name="concepto"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el concepto"
                                }]}
                               
                                >
                                <Input
                                    disabled={this.state.autoincremental}
                                    placeholder="Concepto"></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12} >
                            <Form.Item
                                label="Monto"
                                name="monto"

                                rules={[
                                    {
                                        required: true,
                                        message: "Por favor, ingrese monto"
                                    }
                                ]}
                            >
                                <InputNumber
                                    min={0.01}
                                    disabled={has_pagos}
                                    defaultValue={0} className="width-100"
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : 0}

                                    onChange={monto => this.setState({ monto })}
                                />
                            </Form.Item>
                        </Col>

                        {area && <>
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label="Área"
                                    name="area_id"
                                >
                                    <Select
                                        className="width-100"
                                        disabled={propiedad_id || inversion_id || acreedor_id || has_pagos}
                                        showSearch
                                        filterOption={false}
                                        showArrow
                                        onSearch={search => this.getAreas({ search })}
                                        onSelect={area_id => this.loadRubros({ area_id: area_id?.value ?? area_id })}
                                        labelInValue
                                    >
                                        {this.state.areas.data.map((area) => {
                                            return <Option value={area._id} >{area.nombre}</Option>
                                        })}

                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label="Rubro"
                                    name="rubro_id"
                                >
                                    <Select
                                        className="width-100"
                                        disabled={(propiedad_id || inversion_id || acreedor_id) || !filters.area_id || has_pagos}
                                        filterOption={false}
                                        showArrow
                                        onSearch={search => this.loadRubros({ search })}
                                        onSelect={rubro_id => this.loadRubros({ rubro_id: rubro_id?.value ?? rubro_id })}
                                        labelInValue
                                    >
                                        {this.state.rubros.data.map((rubro) => {
                                            return <Option value={rubro._id} >{rubro.nombre}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </>}

                        {propiedad_id && (
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label="Propiedad"
                                    name="propiedad_id"
                                >
                                    <Select
                                        className="width-100"
                                        disabled={propiedad_id || has_pagos}
                                        showSearch
                                        filterOption={false}
                                        showArrow
                                        //onSearch={search => this.getPropiedades({ search })}
                                        labelInValue
                                        options={[]}
                                    />
                                </Form.Item>
                            </Col>
                        )}
                        {
                            inversion_id && (
                                <Col xs={24} lg={12}>
                                    <Form.Item
                                        label="Inversiones"
                                        name="inversion_id"
                                    >
                                        <Select
                                            className="width-100"
                                            disabled={inversion_id || has_pagos}
                                            showSearch
                                            filterOption={false}
                                            showArrow
                                            //onSearch={search => this.getInversiones({ search })}
                                            labelInValue
                                            options={[]}
                                        />
                                    </Form.Item>
                                </Col>
                            )
                        }
                        {
                            acreedor_id && (
                                <Col xs={24} lg={12}>
                                    <Form.Item
                                        label="Acreedores"
                                        name="acreedor_id"
                                    >
                                        <Select
                                            className="width-100"
                                            disabled={acreedor_id || has_pagos}
                                            showSearch
                                            filterOption={false}
                                            showArrow
                                            //onSearch={search => this.getAcreedores({ search })}
                                            labelInValue
                                            options={[]}
                                        />
                                    </Form.Item>
                                </Col>
                            )
                        }

                        {
                            proveedor_id && (
                                <Col xs={24} lg={12}>
                                    <Form.Item
                                        label="Proveedor"
                                        name="proveedor_id"
                                    >
                                        <SelectProveedor
                                            disabled={proveedor_id || has_pagos}
                                        />
                                    </Form.Item>
                                </Col>
                            )
                        }

                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Proyectos"
                                name="proyecto_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el proyecto"
                                }]}
                            >
                                <Select
                                    showSearch
                                    disabled={propiedad_id || inversion_id || acreedor_id || has_pagos}
                                    filterOption={false}
                                    showArrow
                                    onSearch={search => this.getProyectos({ search })}
                                    labelInValue
                                    className="width-100"
                                >
                                    {this.state.proyectos.data.map(proyecto => {
                                        return <Option value={proyecto._id}>
                                            <Space>
                                                <CustomAvatar size="small" image={proyecto.logo} name={proyecto.alias} color={proyecto.color} /> {proyecto.nombre}
                                            </Space>
                                        </Option>
                                    })}

                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Periodo"
                                name="periodo"

                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el perido"
                                }]}
                            >
                                <Select
                                    disabled={has_pagos}
                                    placeholder="Seleccione periodo"
                                    // onChange={periodo => {
                                    //     const values = this.modalRef.current.getFieldsValue()
                                    //     // fecha_limite
                                    //     // fecha_inicio_limite1
                                    //     this.setState({ periodo })

                                    //     console.log('values', values);
                                    //     if (values.fecha_limite && moment(values.fecha_limite).isBefore(moment())) {
                                    //         console.log('values', values);
                                    //         this.modalRef.current.setFieldsValue({
                                    //             fecha_inicio_limite: [moment(values.fecha_limite), moment()]
                                    //         })
                                    //     }
                                    //     if (values.fecha_inicio_limite && values.fecha_inicio_limite.length > 1) {
                                    //         console.log('values', values.fecha_inicio_limite[0]);
                                    //         this.modalRef.current.setFieldsValue({
                                    //             fecha_limite: values.fecha_inicio_limite[0]
                                    //         })
                                    //     }
                                    // }}
                                >
                                    <Option value={1}>No aplica</Option>
                                    <Option value={2}>Semanal</Option>
                                    <Option value={3}>Quincenal</Option>
                                    <Option value={4}>Mensual</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Tipo"
                                name="tipo"

                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el tipo de transacción"
                                }]}
                            >
                                <Select
                                    disabled={has_pagos}
                                    placeholder="Seleccione el tipo"
                                // onChange={tipo => this.setState({ tipo })}
                                >
                                    <Option value={1}>Ingreso</Option>
                                    <Option value={2}>Egreso</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12} >
                            <Form.Item
                                label="Dias limite"
                                name="dias_limite"

                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la cantidad de días"
                                }]}
                            >
                                <InputNumber
                                    disabled={has_pagos}
                                    className="width-100"
                                // onChange={dias_limite => this.setState({ dias_limite })}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Fecha Limite de Pago"
                                name="fecha_limite"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la fecha"
                                }]}
                            >
                                <DatePicker
                                    className="width-100"
                                    disabled={has_pagos}
                                // onChange={fecha_inicio => this.setState({ fecha_inicio })}
                                />
                            </Form.Item>
                        </Col>
                        {/* {(this.state.abonos.length > 0) && <List
                            className="abonos-list"
                            size="small"
                            bordered={false}
                            dataSource={this.state.abonos}
                            locale={{ emptyText: null, }}
                            style={{ width: "100%", maxHidth: 300 }}
                            renderItem={(abono) => (
                                <List.Item style={{ flex: 1, justifyContent: "space-between" }}>
                                    <div>
                                        {abono.fecha_corte.format("YYYY-MM-DD")} <br />
                                        {this.renderEstatus(abono.estatus)}
                                    </div>
                                    <Typography.Text>{abono.monto?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</Typography.Text>
                                </List.Item>
                            )}
                        />} */}
                        <Divider className="modal-divider">Comprobantes</Divider>
                        <Col xs={24}>
                            <Form.Item
                                label="Archivos e imagenes"
                                name="comprobantes"
                                valuePropName="fileList"
                                getValueFromEvent={e => {
                                    if (Array.isArray(e)) {
                                        return e;
                                    }
                                    return e?.fileList;
                                }}>
                                <Uploader
                                    {...this.props.transaccion_programada_id ? {
                                        method: "PUT",
                                        name: "comprobante",
                                        headers: {
                                            authorization: sessionStorage.getItem("token")
                                        },
                                        onRemove: e => {
                                            axios.put('/transacciones-programadas', {
                                                comprobante: e.uid,
                                                id: this.props.transaccion_programada_id
                                            })
                                        }
                                    } : {}}>
                                    <Button icon={<UploadOutlined />} block size="small">Subir Documentos </Button>
                                </Uploader>
                            </Form.Item>

                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, transaccion_programada_id, clasificacion } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={transaccion_programada_id ? "Editar Transacción Programada" : "Nueva Transacción Programada"}
        closable={true}
        maskClosable={true}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-transaccion', key: 'submit', htmlType: 'submit' }}
    >
        <ModalTransaccionProgramada {...props} />
    </Modal>

}