import React, { Component } from "react";
import { Modal, Form, Input, message, Spin, Row, Col, Button, Select, InputNumber } from 'antd';
import SelectInmueble from "../../Widgets/Inputs/SelectInmueble";
import SelectProyecto from "../../Widgets/Inputs/SelectProyecto";
import SelectProducto from "../../Widgets/Inputs/SelectProducto";
import Selectempleado from "../../Widgets/Inputs/SelectEmpleado";
import SelectAlmacen from "../../Widgets/Inputs/SelectAlmacen";

const axios = require('axios').default;


/**
 *
 *
 * @class ModalInventario
 * @extends {Component}
 */
class ModalInventario extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            producto: {}
        }
    }

    ModalInventario = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    }

    componentDidUpdate(prevProps, prevState){
        if(
            (this.state.producto_id && this.state.almacen_id) &&
            (this.state.producto_id !== prevState.producto_id || this.state.almacen_id !== prevState.almacen_id)
        ){
            this.getInventario()
        }
    }

    getInventario = ({
        producto_id,
        almacen_id
    } = this.state) => {
        this.setState({loading: true})
        axios.get('/almacenes/producto-almacen', {
            params: {
                almacen_id,
                producto_id
            }
        }).then(({data}) => {
            this.setState({inventario: data})
        }).catch(error => {
            message.info("El inventario no existe, selecciona otro producto/almacen o crea el inventario")
        }).finally(() => {
            this.setState({loading: false})
        })
    }

    /**
     * @memberof ModalInventario
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     */
    onFinish = (values) => {
        this.setState({loading: true})
        axios.put(`/almacenes/producto-movimiento/${this.state.inventario?._id}`, {
            ...values
        }).then(() => {
            message.success("Movimiento realizado")
            this.props.onClose()
        }).catch(error => {
            message.error("Error al hacer el movimiento")
        }).finally(() => {
            this.setState({loading: false})
        })
    }
    

    render() {
        const { inventario } = this.state
        const max = this.state.tipo === 1 ? inventario?.inventario_maximo - inventario?.cantidad : inventario?.cantidad - inventario?.inventario_minimo

        return (
            <Form
                layout="vertical"
                ref={this.ModalInventario}
                onFinish={this.onFinish}
                className="pd-1"
                name="form-inventario"
            >
                <Spin spinning={this.state.loading}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Maestro"
                                name={'empleado_id'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor, seleccione el empleado',
                                    }
                                ]}
                            >
                                <Selectempleado/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Tipo de Operacion"
                                name="tipo"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el tipo de operación"
                                }]}
                            >
                                <Select 
                                    placeholder="Selecciona la operación"
                                    onSelect={tipo => this.setState({tipo})}
                                >
                                    <Select.Option value={1}>Entrada</Select.Option>
                                    <Select.Option value={2}>Salida</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Proyecto"
                                name={'proyecto_id'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Seleccione el proyecto',
                                    }
                                ]}
                                
                            >
                                <SelectProyecto
                                    onSelect={(proyecto_id) => {
                                        this.setState({proyecto_id})
                                        this.ModalInventario.current?.setFieldsValue({
                                            almacen_id: null,
                                            inmueble_id: null,
                                            producto_id: null
                                        })
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Inmueble"
                                name={'inmueble_id'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Seleccione el inmueble',
                                    }
                                ]}
                            >
                                <SelectInmueble disabled={!this.state.proyecto_id} proyecto_id={this.state.proyecto_id}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Almacen"
                                name={'almacen_id'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Seleccione el almacen',
                                    }
                                ]}
                            >
                                <SelectAlmacen 
                                    onSelect={almacen_id => {
                                        this.ModalInventario.current?.setFieldsValue({
                                            producto_id: null
                                        })
                                        this.setState({almacen_id})
                                    }}
                                    disabled={!this.state.proyecto_id} 
                                    proyecto_id={this.state.proyecto_id}
                                />
                            </Form.Item>
                        </Col>
                        
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Producto"
                                name={'producto_id'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Seleccione el producto',
                                    }
                                ]}
                            >
                                <SelectProducto
                                    disabled={!this.state.almacen_id || !this.state.proyecto_id}
                                    params={{
                                        almacen_id: this.state.almacen_id
                                    }}
                                    onSelect={(producto_id, [args]) => {
                                        this.setState({producto_id, producto: args?.producto})
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                label="Volumen"
                                name={'volumen'}
                                help={inventario ? `Maximo: ${max}`: ''}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese el monto',
                                    }
                                ]}
                            >
                                <InputNumber min={0.01} 
                                    max={max} 
                                    disabled={!inventario || this.state.loading || !this.state.tipo} 
                                    addonAfter={this.state.producto?.unidad?.nombre || "Unidad"} style={{width: '100%'}} defaultValue={0} precision={2}  placeholder="Cantidad"/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, categoria_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={"Movimientos de Inventario"}
        closable={true}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-inventario', key: 'submit', htmlType: 'submit' }}
    >
        <ModalInventario {...props} />
    </Modal>

}