import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, InputNumber, Select, message, DatePicker, Spin, Divider } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

//componentes
import Avatar from "../../../Widgets/Avatar/Avatar";
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import { SimpleUploader, Uploader } from "../../../Widgets/Uploaders";
import { IconCloseModal, IconArrowSelect } from '../../../Widgets/Iconos';
import { SelectProyecto, SelectUsuario } from "../../../Widgets/Inputs/Selects";

const { Title, Text } = Typography;
const { Option } = Select;
const axios = require('axios').default;
const moment = require('moment');

class ModalInversion extends Component {

    modalInversion = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            dataAreas: [],
            dataRubros: [],
            dataProyectos: [],
            areas: {
                data: [],

                page: 1,
                limit: 15,

                pages: 0,
                total: 0,
                search: null
            },
            rubros: {
                data: [],

                page: 1,
                limit: 15,

                pages: 0,
                total: 0,
                search: null
            },
            usuarios: {
                data: [],

                page: 1,
                limit: 15,

                pages: 0,
                total: 0,
                search: null
            },
            proyectos: {
                data: [],

                page: 1,
                limit: 15,

                pages: 0,
                total: 0,
                search: null
            },
            filters: {},
            loading: false,
            proyecto: {}
        }
    }

    /**
    * @memberof ModalInversion
    * @method   componentDidMount
    * @description  Al montar el componente:
    * Se inicializa header de axios
    **/
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getAreas()
        this.getRubros()
        this.getProyectos()
        this.getUsuarios()

        if (this.props.inversion_id !== undefined) {
            this.getInversion(this.props.inversion_id)
        }
    }


    getInversion = (_id) => {
        this.setState({ loading: true })
        axios.get('/inversiones/get', {
            params: {
                _id
            }
        }).then(response => {

            //Actualiza los valores del form dentro del modal.
            let data = response.data.data.inversion

            console.log("data", data.proyecto_id)

            this.setState({
                areas: { data: [...this.state.areas.data, data.area_id] },
                rubros: { data: [...this.state.rubros.data, data.rubro_id] },
                proyectos: { data: [...this.state.proyectos.data, data.proyecto_id] },
                usuarios: { data: [...this.state.usuarios.data, data.usuario_id] },
            }, console.log(this.state))

            this.modalInversion.current.setFieldsValue({
                nombre: data.nombre,
                area_id: data.area_id._id,
                rubro_id: data.rubro_id._id,
                proyecto_id: data.proyecto_id._id,
                usuario_id: data.usuario_id._id,
                fecha_venta: moment(data.fecha_venta),
                monto_venta: data.monto_venta,
                valor_estimado_actual: data.valor_estimado_actual,
                documentos: data?.documentos?.map((file, index) => ({
                    uid: file.file,
                    name: file.name,
                    status: 'done',
                    fromDB: true,
                    url: axios.defaults.baseURL + "/inversiones/get?_id=" + this.props.inversion_id + "&documento=" + file.file + "&Authorization=" + sessionStorage.getItem("token")
                })),
            });

        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        }).finally(() => this.setState({ loading: false }))
    }


    getAreas = ({

        page = this.state.areas.page,
        limit = this.state.areas.limit,
        search = this.state.areas.search

    } = this.state.areas) => {

        this.setState({ areas: { ...this.state.areas, loading: true, page, limit, search } })
        axios.get('/areas', {
            params: {
                page,
                limit,
                search

            }
        }).then(({ data }) => {
            this.setState({
                areas: data,

            });
        }).catch(error => {
            console.log(error)
            message.error('Error al traer las areas')
        })
    }

    getRubros = ({

        page = this.state.rubros.page,
        limit = this.state.rubros.limit,
        search = this.state.rubros.search,
        area_id = this.state.filters.area_id

    } = this.state.rubros) => {

        this.setState({ rubros: { ...this.state.rubros, loading: true, page, limit, search }, filters: { ...this.state.filters, area_id } })
        axios.get('/rubros', {
            params: {
                page,
                limit,
                search,
                area_id
            }
        }).then(({ data }) => {
            this.setState({
                rubros: data,

            });
        }).catch(error => {
            console.log(error)
            message.error('Error al traer los rubros')
        })
    }

    /**
    * @const getProyectos
    * @description Obitiene los proyectos
    */
    getProyectos = ({ page, limit, search } = this.state.proyectos) => {

        axios.get('/proyectos', {
            params: {
                page,
                limit,
                search,
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(({ data }) => {
            this.setState({
                proyectos: data,

            });
        }).catch(error => {
            console.log("ERR, error", error)
            message.error(error?.response?.data?.message ?? 'Error al obtener los proyectos')
        })
    }

    /**
     * @const getUsuarios
     * @description Obitiene los usaurios
     */
    getUsuarios = ({
        page, 
        limit, 
        search, 
    } = this.state.usuarios) => {

        axios.get('/usuarios', {
            params: {
                page,
                limit,
                search,
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(({ data }) => {
            this.setState({
                usuarios: data,

            });
        }).catch(error => {
            console.log("ERR, error",error)
            message.error(error?.response?.data?.message ?? 'Error al obtener los usuarios')
        })
    }



    /**
      * @memberof ModalInversion
      * @method   onFinish
      * @description  Al completar formulario :
      * Se valida el tipo de accion modal para crear nuevo material o editar el registro seleccionado
      **/
    onFinish = async (values) => {

        for (const key in values) {
            if (values[key]?.label)
                values[key] = values[key]?.value
        }

        const formData = new FormData()

        formData.appendMultiple({
            ...values,
            _id: this.props.inversion_id,
            documentos: undefined
        });

        values.documentos?.forEach(e => {
            if (!e.fromDB) formData.append("documentos", e, e.name)
        })

        if (this.props.inversion_id) {
            this.update(formData)
        } else {
            this.save(formData)
        }


    }

    /**
      * @memberof ModalInversion
      * @method   save
      * @description crea una nueva inversion
      **/
    save = async (values) => {
        axios.post('/inversiones/add', values)
            .then(response => {
                message.success('Inversion Creada')
                this.props.onClose()
            }).catch(error => {
                message.error('Error')
                console.log(error)
            })
    }

    /**
      * @memberof ModalInversion
      * @method   update
      * @description Actualiza la informacion de una inversion
      **/
    update = async (values) => {
        axios.post('/inversiones/update', values)
            .then(response => {
                message.success('Inversion Actualizada')
                this.props.onClose()
            }).catch(error => {
                message.error('Error')
                console.log(error)
            })
    }


    renderUrl = (inversion_id, filename) => {
        const urlParams = new URL(axios.defaults.baseURL + "/inversiones/images/" + inversion_id)
        urlParams.searchParams.set("Authorization", sessionStorage.getItem("token"))
        urlParams.searchParams.set("file", filename)
        return urlParams.href
    }


    render() {

        return (
            <Spin spinning={this.state.loading}>
                <Form
                    layout="vertical"
                    className="frm-cuentas"
                    name="form-materiales"
                    ref={this.modalInversion}
                    onFinish={this.onFinish}

                >
                    <Row align="center" gutter={20}>
                        <Col sm={24} lg={24}>
                            <Form.Item
                                label="Nombre"
                                name="nombre" //<--------------------------------------- nombre
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese un material"
                                }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col sm={24} lg={12}>
                            <Form.Item
                                label="Área"
                                name="area_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el Area."
                                }]}
                            >
                                <Select
                                    className="width-100"
                                    showSearch
                                    filterOption={false}
                                    showArrow
                                    onSearch={search => this.getAreas({ search })}
                                    onSelect={area_id => this.getRubros({ area_id: area_id?.value ?? area_id })}
                                    labelInValue
                                >
                                    {this.state.areas.data.map((area) => {
                                        return <Option value={area._id} >{area.nombre}</Option>
                                    })}

                                </Select>
                            </Form.Item>

                        </Col>

                        <Col sm={24} lg={12} >
                            <Form.Item
                                label="Rubro"
                                name="rubro_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el Rubro"
                                }]}
                            >
                                <Select
                                    className="width-100"
                                    disabled={!this.state.filters?.area_id}
                                    showSearch
                                    filterOption={false}
                                    showArrow
                                    onSearch={search => this.getRubros({ search })}
                                    labelInValue
                                >
                                    {this.state.rubros.data.map((rubro) => {
                                        return <Option value={rubro._id} >{rubro.nombre}</Option>
                                    })}

                                </Select>
                            </Form.Item>

                        </Col>



                        <Col sm={24} lg={12}>
                            <Form.Item
                                label="Monto Venta"
                                name="monto_venta" //<--------------------------------------- monto_venta
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese un monto"
                                }]}
                            >
                                <InputNumber
                                    disabled={false}
                                    min={0.01}
                                    className="width-100"
                                    decimalSeparator=","
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : 0}
                                />
                            </Form.Item>
                        </Col>
                        <Col sm={24} lg={12} >
                            <Form.Item
                                label="Proyecto"
                                name="proyecto_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione un Proyecto"
                                }]}
                            >
                                <Select
                                    className="width-100"
                                    showSearch
                                    filterOption={false}
                                    showArrow
                                    onSearch={search => this.getProyectos({ search })}
                                    labelInValue
                                >
                                    {
                                        this.state.proyectos?.data?.map(({ _id, nombre, color, logo }) => <Option value={_id} key={_id}>
                                            <CustomAvatar
                                                size="small"
                                                url={{
                                                    url: `${axios.defaults.baseURL}/proyectos/logo/${_id}`,
                                                    name: logo?.name
                                                }}
                                                color={color}
                                                name={nombre}
                                            />
                                            {nombre ?? ""}
                                        </Option>)
                                    }
                                </Select>
                            </Form.Item>

                        </Col>
                        <Col sm={24} lg={12} >
                            <Form.Item
                                label="Contacto"
                                name="usuario_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione un Contacto"
                                }]}
                            >
                                <Select
                                    className="width-100"
                                    showSearch
                                    filterOption={false}
                                    showArrow
                                    onSearch={search => this.getUsuarios({ search })}
                                    labelInValue
                                >
                                    {
                                        this.state.usuarios?.data?.map(({ _id, nombre, apellidos }) => <Option value={_id} key={_id}>{nombre ?? ""} {apellidos ?? ""}</Option>)
                                    }
                                </Select>
                            </Form.Item>

                        </Col>

                        <Col sm={24} lg={12}>
                            <Form.Item
                                label="Fecha de Venta"
                                name="fecha_venta" //<--------------------------------------- fecha_venta
                            >
                                <DatePicker className="width-100" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={24}>
                            <Divider>Información del Prestamo</Divider>
                        </Col>
                    </Row>

                    <Row gutter={20}>
                        <Col span={24}>
                            <Form.Item
                                label="Monto Estimado Actual"
                                name="valor_estimado_actual" //<--------------------------------------- valor_estimado_actual
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese un monto"
                                }]}
                            >
                                <InputNumber
                                    disabled={false}
                                    min={0.01}
                                    className="width-100"
                                    decimalSeparator=","
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : 0}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row align="center" gutter={20}>
                    <Col span={24}>
                            <Form.Item
                                label="Documentos"
                                name="documentos"
                                valuePropName="fileList"
                                getValueFromEvent={e => {
                                    if (Array.isArray(e)) {
                                        return e;
                                    }
                                    return e?.fileList;
                                }}>
                                <Uploader
                                    {...this.props.inversion_id ? {
                                        method: "POST",
                                        name: "documentos",
                                        headers: {
                                            authorization: sessionStorage.getItem("token")
                                        },
                                        //action: axios.defaults.baseURL + '/transacciones',
                                        onRemove: e => {
                                            axios.post('/inversiones/update', {
                                                documento: e.uid,
                                                _id: this.props.inversion_id
                                            })
                                        }
                                    } : {}}>
                                    <Button icon={<UploadOutlined />} block size="small">Subir Documentos </Button>
                                </Uploader>
                            </Form.Item>
                        </Col>
                    </Row>


                </Form>
            </Spin>
        )
    }
}



export default function (props) {

    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={`${props.inversion_id ? "Editar" : "Crear"} Inversión`}

        closable={true}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-materiales', key: 'submit', htmlType: 'submit' }}
    >
        <ModalInversion {...props} />
    </Modal>

}