import React, { Component, useContext } from "react";
import { Button, Col, Row, Typography, Card, List, Space, message, Popconfirm, Modal, Statistic, Tooltip, Tag } from "antd";
import { CheckCircleOutlined, ClockCircleOutlined, CloseOutlined, DeleteOutlined, DollarCircleOutlined, EditOutlined, EyeOutlined, FilePdfOutlined, LinkOutlined } from "@ant-design/icons"
import axios from 'axios'


import { BiEnvelope } from "react-icons/bi"
import CustomAvatar from "../../../../Widgets/Avatar/Avatar";
import ModalTransaccionProgramada from "../../../Finanzas/Transacciones/ModalTransaccionProgramada";
import usePermissions from "../../../../../Hooks/usePermissions";
import User from "../../../../../Hooks/Logged";
import { IconCheckMedalColor } from '../../../../Widgets/Iconos';

const { Text } = Typography;
const moment = require('moment');

/**
 * @export
 * @class TransaccionesProgramadasTab
 * @extends {Component}
 * @description Lista de transacciones del cliente
 */
class TransaccionesProgramadasTab extends Component {

	constructor(props) {
		super(props)
		this.state = {
			proyecto: undefined,
			transacciones: {
				data: [],
				limit: 20,
				page: 1,
				total: 0,
				pages: 0,
			},
			propiedad_id: this.props.propiedad_id,
			transaccion_programada_id: undefined
		}
	}

	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
		this.get()
	}

	componentDidUpdate(prevProps) {
		if (prevProps.propiedad_id !== this.props.propiedad_id) this.get()
	}

	/**
	 * @memberof TransaccionesProgramadasTab
	 * @method get
	 * @description Obtiene los transacciones del cliente
	 *
	 */
	get = ({
		page = this.state.transacciones.page,
		limit = this.state.transacciones.limit,
	} = this.state.transacciones, { propiedad_id } = this.props) => {
		this.setState({ loading: true })
		axios.get('/transacciones-programadas', {
			params: {
				propiedad_id: this.props.propiedad_id,
				page,
				limit,
			}
		})
			.then(({ data }) => {
				console.log("data", data);
				this.setState({ transacciones: { ...this.state.transacciones, ...data } });
			})
			.catch(error => {
				message.error('Error al traer los transacciones programadas')
				console.log(error.response)
			}
			).finally(() => this.setState({ loading: false }))
	}



	diasAtraso = (item) => {
		if (item.monto_pagado == item.monto)
			return

		const fecha_limite = moment(item.fecha_limite)

		console.log('fecha_limite', fecha_limite);

		//Si la fecha actual esta dsepues de la fecha limite
		if (moment().isAfter(fecha_limite))
			return <div style={{ position: "absolute", right: '-100%', top: 0, color: "#F50" }}>
				(-{moment().diff(fecha_limite, 'day')})
			</div>

		if (!fecha_limite.subtract(item.dias_limite, 'days').isAfter(moment()))
			return <div style={{ position: "absolute", right: '-100%', top: 0, color: "#FBBC30" }}>
				({moment().diff(fecha_limite, 'day')})
			</div>
	}

	renderEstatus = (item) => {
		if (item.monto_pagado == item.monto)
			return <Tag color="#87d068" icon={<CheckCircleOutlined />}><strong>PAGADO</strong></Tag>

		const fecha_limite = moment(item.fecha_limite)

		//Si la fecha actual esta dsepues de la fecha limite
		if (moment().isAfter(fecha_limite))
			return <Tag color="#F50">Vencida</Tag>

		if (fecha_limite.subtract(item.dias_limite, 'days').isAfter(moment()))
			return <Tag color="#2db7f5">Pendiente</Tag>

		return <Tag color="#FBBC30">Proxima a pagar</Tag>
	}

	render() {

		const { data } = this.state.transacciones

		return (
			<>
				<Row gutter={[8, 8]}>
					<List
						itemLayout="horizontal"
						dataSource={data}
						loading={this.state.loading}
						key={(item => item._id)}
						className="component-list width-100"
						pagination={{
							current: this.state.transacciones.page,
							pageSize: this.state.transacciones.limit,
							total: this.state.transacciones.total,
							showSizeChanger: true,
							position: 'bottom',
							className: "flex-left",
							onChange: (page, limit) => this.get({ page, limit })
						}}
						header={<Row className="width-100" align={"middle"} gutter={[16, 16]}>
							<Col xs={12} md={3} className="center">
								<strong>CONCEPTO</strong>
							</Col>
							<Col xs={12} md={3} className="center">
								<strong>FECHA DE CREACIÓN</strong>
							</Col>
							<Col xs={12} md={3} className="center">
								<strong>FECHA DE PAGO</strong>
							</Col>
							<Col xs={12} md={4} className="center">
								<strong>MONTO</strong>
							</Col>
							<Col xs={12} md={4} className="center">
								<strong>MONTO PAGADO</strong>
							</Col>
							<Col xs={12} md={4} className="center">
								<strong>MONTO PENDIENTE</strong>
							</Col>
							<Col xs={12} md={1} className="center">
								<strong>ESTATUS</strong>
							</Col>
							<Col xs={24} md={2} align="right"></Col>
						</Row>}
						renderItem={item => (
							<List.Item className="component-list-item transacciones">
								<Card className="ant-card-list width-100" style={{ position: "relative" }}>
									{(item.tipo == 1) ?
										<div style={{ height: "100%", background: "#87d068", width: 7, borderRadius: "10px 0px 0 10px ", position: "absolute", left: 0, top: 0 }} />
										:
										<div style={{ height: "100%", background: "#ff4d4f", width: 7, borderRadius: "10px 0px 0 10px ", position: "absolute", left: 0, top: 0 }} />
									}
									<Row className="width-100" align={"middle"} gutter={[16, 16]}>
										<Col xs={12} md={3} className="center">
											{item.concepto}
										</Col>
										<Col xs={12} md={3} className="center">
											{moment(item.createdAt).format('YYYY-MM-DD')}
										</Col>
										<Col xs={12} md={3} className="center">
											<span style={{ position: "relative" }}>
												{this.diasAtraso(item)}
												{moment(item.fecha_limite).format('YYYY-MM-DD')}
											</span>
										</Col>
										<Col xs={12} md={4} className="center">
											{item.monto.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })}
										</Col>
										<Col xs={12} md={4} className="center">
											{item.monto_pagado.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })}
										</Col>
										<Col xs={12} md={4} className="center">
											{item.monto_pendiente.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })}
										</Col>
										<Col xs={12} md={1} className="center">
											{this.renderEstatus(item)}
										</Col>
										<Col xs={24} md={2} align="right">
											<Space direction="horizontal" size={5}>
												<Tooltip mouseEnterDelay={0} title="Editar la transacción programada" placement="rightTop">
													<Button
														size="small"
														type="primary"
														disabled={!this.props.editarTransaccionesProgramadasPropiedad}
														icon={<EditOutlined style={{ color: "currentcolor" }} />}
														onClick={() => this.setState({ modalVisible: true, transaccion_programada_id: item._id })}
													/>
												</Tooltip>

												<Popconfirm
													placement="topRight"
													title="¿Deseas eliminar esta transacción programda?"
													onConfirm={() => axios.delete(`/transacciones-programadas`,
														{ params: { id: item._id } }).then(() => {
															this.get()
															message.success('Transacción programda eliminada')
															this.props.updateAll()
														}).catch(error => {

															if (Array.isArray(error?.response?.data)) {

																Modal.error({
																	title: "Hay transacciones relacionadas con esta transacción programada",
																	content: <List

																		size="small"
																		bordered={false}
																		dataSource={error.response.data}
																		renderItem={(item) => <List.Item
																		>
																			<List.Item.Meta
																				// avatar={<Avatar src={`https://xsgames.co/randomusers/avatar.php?g=pixel&key=${index}`} />}
																				title={item.concepto}
																				description={
																					<>
																						Monto: {item.monto?.toMoney()} <br />
																						Fecha de Creación: {moment(item.createdAt).format("LLL")}
																					</>
																				}
																			/>
																		</List.Item>}
																	/>
																})


															} else
																message.delete(error.response)

															console.log()
														})}
													okText="Si"
													cancelText="No"
												>
													<Button
														size="small"
														type="primary"
														disabled={!this.props.eliminarTransaccionesProgramadasPropiedad}
														icon={<DeleteOutlined style={{ color: "currentcolor" }} />}
														title="Eliminar"
														danger
													/>
												</Popconfirm>
											</Space>
										</Col>
									</Row>
								</Card>
							</List.Item>
						)}
					/>
				</Row>

				<ModalTransaccionProgramada
					visible={this.state.modalVisible}
					onClose={() => {
						this.setState({ modalVisible: false, transaccion_programada_id: undefined })
						this.props.updateAll()
					}}
					propiedad_id={this.props.propiedad_id}
					transaccion_programada_id={this.state.transaccion_programada_id}
					area={true}
					rubro={true}
				/>
			</>
		)
	}
}

export default React.forwardRef((props, ref) => {

	const user = useContext(User);

	const permisos = usePermissions(user?.rol_id?.permisos, {
		editarTransaccionesProgramadasPropiedad: ["clientes", "propiedades", "transacciones-programadas", "edit"],
		eliminarTransaccionesProgramadasPropiedad: ["clientes", "propiedades", "transacciones-programadas", "delete"]
	});

	return <TransaccionesProgramadasTab ref={ref} {...props} user={user} {...permisos} />
})