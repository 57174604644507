
import React from 'react';

import { Drawer, Form, Select, Cascader, message, Badge, Button, DatePicker, Space, Typography, Checkbox } from 'antd';
import axios from 'axios';


import CAvatar from '../Avatar/Avatar';
import { DownloadOutlined } from '@ant-design/icons';
import { SelectInversion, SelectAcreedor, SelectInmueble } from "../Inputs/Selects"
import Selectempleado from '../Inputs/SelectEmpleado';

const { Option } = Select
const { RangePicker } = DatePicker
const { Text } = Typography

/**
 *
 *
 * @export
 * @class FiltrosDrawer
 * @extends {React.Component}
 * 
 * @description Permite filtrar según diversa información de la vista en la que se implemente
 */
export default class FiltrosDrawer extends React.Component {

    /**
     *
     *
     * @export
     * @class FiltrosDrawer
     * @extends {React.Component}
     * 
     * @state cuentas Objeto para paginar y buscar en el select de cuentas 
     * @state cuentas Objeto para paginar y buscar en el select de cuentas 
     * @state ordenes Objeto para paginar y buscar en el select de ordenes
     * @state ordenes_compras Objeto para paginar y buscar en el select de ordenes de compra
     * @state areas Objeto para paginar y buscar en el select de areas  
     * @state areas_dictionary Diccionario de Areas. Ya que el select solo nos da el ID, utilizamos este diccionario para obtener el objeto  
     * @state rubros_dictionary Diccionario de Rubros. Ya que el select solo nos da el ID, utilizamos este diccionario para obtener el objeto  
     * @state empresas Objeto para paginar y buscar en el select de Empresas
     * @state clasificadores Objeto para paginar y buscar en el select de Clasificadores 
     * 
     * @state paginator.data Lista de elementos para paginar
     * @state paginator.dictionary Ya que los selects funcionan solo con ID´s, utilizamos el diccionario para obtener el objeto al que el ID hace referencia
     * @state paginator.page Pagina actual
     * @state paginator.limit Limit de elementos por página
     * @state paginator.pages Total de paginas
     * @state paginator.total Total de Elementos en la lista
     * @state paginator.search Para buscar
     * @state paginator.search Loading, para declarar el actualizado.
     */
    state = {


        cuentas: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        },
        clientes: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false,
            disabled: false
        },

        proyectos_inmueble: [],

        proyectos: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        },

        ordenes: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        },

        ordenes_compras: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        },


        areas: [],
        areas_dictionary: {},
        rubros_dictionary: {},

        empresas: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        },

        inversiones: {
            data: [],
            dictionary: {},
            page: 1,
            limit: 15,
            pages: 0,
            total: 0,
            search: null,
            loading: false
        },
        acreedores: {
            data: [],
            dictionary: {},
            page: 1,
            limit: 15,
            pages: 0,
            total: 0,
            search: null,
            loading: false
        },

        clasificadores: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        },

        negocios: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        },
        proveedores: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        },
        proyectos_dictionary: {},
        inmuebles_dictionary: {},
        fechas: {
            dictionary: {
                fecha: []
            }
        },
        proyecto_ids: []
    }

    /**
     *
     *
     * @memberof FiltrosDrawer
     * @methodof componentDidMount
     * @description Obtenemos todas las listas
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        console.log("PROPS", this.props)

        if (this.props.cuentas === undefined || this.props.cuentas !== false)
            this.getCuentas()

        if (this.props.clientes === undefined || this.props.clientes !== false)
            this.getClientes()

        if (this.props.ordenes === undefined || this.props.ordenes !== false)
            this.getOrdenes()

        if (this.props.razones_sociales === undefined || this.props.razones_sociales !== false)
            this.getRazonesSociales()

        if (this.props.proyectos_inmueble === undefined || this.props.proyectos_inmueble !== false)
            this.getProyectosInmueble()

        if (this.props.proyectos === undefined || this.props.proyectos !== false)
            this.getProyectos()

        // if (this.props.orden_compra === undefined || this.props.orden_compra !== false)
        //     this.getOrdenesCompras()

        if (this.props.area_rubro === undefined || this.props.area_rubro !== false)
            this.getAreas();



        // if (this.props.empresas === undefined || this.props.empresas !== false)
        //     this.getRazonesSociales()

        // if (this.props.clasificadores === undefined || this.props.clasificadores !== false)
        //     this.getClasificadores()

        // if (this.props.negocios === undefined || this.props.negocios !== false)
        //     this.getNegocios()


        // if (this.props.proveedores === undefined || this.props.proveedores !== false)
        //     this.getProveedores()

    }

    /**
     *
     *
     * @param {*} [{ page, limit, search }=this.state.cuentas]
     * @param {*} [{ cuentas }=this.state]
     * @memberof FiltrosDrawer
     * 
     * @description Para obtener las cuentas en la lista
     */
    getCuentas = ({ page, limit, search } = this.state.cuentas, { cuentas } = this.state) => {

        cuentas.loading = true;
        cuentas.page = page;
        cuentas.limit = limit;
        cuentas.search = search
        this.setState({ cuentas })

        axios.get('/cuentas', {
            params: {
                page, limit, search
            }
        })
            .then(({ data }) => {
                cuentas.data = (page === 1) ? data.data : [...cuentas.data, ...data.data];

                cuentas.dictionary = {}
                cuentas.data.map(d => cuentas.dictionary[d._id] = d)

                cuentas.total = data.total
                cuentas.pages = data.pages
                cuentas.loading = false;

                this.setState({ cuentas })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.cuentas.loading = false
                    return state
                })
            })
    }

    /**
 *
 *
 * @param {*} [{ page, limit, search }=this.state.cuentas]
 * @param {*} [{ cuentas }=this.state]
 * @memberof FiltrosDrawer
 * 
 * @description Para obtener las cuentas en la lista
 */
    getInmuebles = ({ page, limit, search } = this.state.inmuebles, { inmuebles } = this.state) => {

        inmuebles.loading = true;
        inmuebles.page = page;
        inmuebles.limit = limit;
        inmuebles.search = search
        this.setState({ inmuebles })

        axios.get('/inmuebles', {
            params: {
                page, limit, search
            }
        })
            .then(({ data }) => {
                inmuebles.data = (page === 1) ? data.data : [...inmuebles.data, ...data.data];

                inmuebles.dictionary = {}
                inmuebles.data.map(d => inmuebles.dictionary[d._id] = d)

                inmuebles.total = data.total
                inmuebles.pages = data.pages
                inmuebles.loading = false;

                this.setState({ inmuebles })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.inmuebles.loading = false
                    return state
                })
            })
    }

    /**
     *
     *
     * @param {*} [{ page, limit, search }=this.state.clientes]
     * @param {*} [{ clientes }=this.state]
     * @memberof FiltrosDrawer
     * @method getClientes
     * 
     * @description Para obtener los clientes en la lista
     */
    getClientes = ({ page, limit, search } = this.state.clientes, { clientes } = this.state) => {

        clientes.loading = true;
        clientes.page = page;
        clientes.limit = limit;

        this.setState({ clientes })

        axios.get('/clientes', {
            params: {
                page, limit, search
            }
        })
            .then(({ data }) => {
                clientes.data = (page === 1) ? data.data : [...clientes.data, ...data.data];

                clientes.dictionary = {}
                clientes.data.map(d => clientes.dictionary[d._id] = d)

                clientes.total = data.total
                clientes.pages = data.pages
                clientes.loading = false;

                this.setState({ clientes })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.clientes.loading = false
                    return state
                })
            })
    }

    /**
     * @const getProyectosInmueble
     * @description Obitiene los proyectos
     */
    getProyectosInmueble = () => {

        return axios.get('/proyectos', {
            params: {
                paginate: false,
                filtros: true
            }
        }).then(({ data }) => {
            console.log(" - - -  ");
            console.log(" - - -  ");
            console.log(" - - -  ");
            console.log(" - - -  ", data);
            this.setState({ proyectos_inmueble: data.data })
        }).catch(error => {
            console.log('error al traer proyectos', error)
        })
    }

    /**
    * @const getProyectos
    * @description Obitiene los proyectos
    */
    getProyectos = ({ page, limit, search } = this.state.proyectos, { proyectos } = this.state) => {

        axios.get('/proyectos', {
            params: {
                page,
                limit,
                search,
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(({ data }) => {
            proyectos.data = (page === 1) ? data.data : [...proyectos.data, ...data.data];

            proyectos.dictionary = {}
            proyectos.data.map(d => proyectos.dictionary[d._id] = d)

            proyectos.total = data.total
            proyectos.pages = data.pages
            proyectos.loading = false;

            this.setState({ proyectos })
        })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.proyectos.loading = false
                    return state
                })
            })
    }

    /**
     *
     *
     * @param {*} [{ page, limit, search }=this.state.ordenes]
     * @param {*} [{ ordenes }=this.state]
     * @memberof FiltrosDrawer
     * @method getOrdenes
     * 
     * @description Para obtener las ordenes en la lista
     */
    getOrdenes = ({ page, limit, search, clientes_ids } = this.state.ordenes, { ordenes } = this.state) => {

        ordenes.loading = true;
        ordenes.page = page;
        ordenes.limit = limit;
        ordenes.clientes_ids = clientes_ids

        this.setState({ ordenes })

        axios.get('/ordenes', {
            params: {
                page, limit, search, cliente_id: clientes_ids
            }
        })
            .then(response => {

                ordenes.data = (page === 1) ? response.data.data.itemsList : [...ordenes.data, ...response.data.data.itemsList];

                ordenes.dictionary = {}
                ordenes.data.map(d => ordenes.dictionary[d._id] = d)

                ordenes.total = response.data.data.paginator.itemCount
                ordenes.pages = response.data.data.paginator.pageCount
                ordenes.loading = false;

                this.setState({ ordenes })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.ordenes.loading = false
                    return state
                })
            })
    }

    /**
     *
     *
     * @param {*} [{ page, limit, search }=this.state.ordenes_compras]
     * @param {*} [{ ordenes_compras }=this.state]
     * @memberof FiltrosDrawer
     * @method getOrdenesCompras
     * 
     * @description Para obtener las ordenes de compra en la lista
     */
    getOrdenesCompras = ({ page, limit, search, orden_id } = this.state.ordenes_compras, { ordenes_compras } = this.state) => {

        ordenes_compras.loading = true;
        ordenes_compras.page = page;
        ordenes_compras.limit = limit;

        this.setState({ ordenes_compras })

        axios.get('/ordenesCompra/list', {
            params: {
                page, limit, search, orden_id
            }
        })
            .then(response => {
                ordenes_compras.data = (page === 1) ? response.data.data.data : [...ordenes_compras.data, ...response.data.data.data];

                ordenes_compras.dictionary = {}
                ordenes_compras.data.map(d => ordenes_compras.dictionary[d._id] = d)

                ordenes_compras.total = response.data.data.total
                ordenes_compras.pages = response.data.data.pages
                ordenes_compras.loading = false;

                this.setState({ ordenes_compras })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.ordenes_compras.loading = false
                    return state
                })
            })
    }

    /**
     *
     *
     * @param {*} [{ page, limit, search }=this.state.areas]
     * @param {*} [{ areas }=this.state]
     * @memberof FiltrosDrawer
     * @method getAreas
     * 
     * @description Para obtener las areas en la lista
     */
    getAreas = () => {
        return axios.get('/areas', {
            params: {
                paginate: false,
                filtros: true
            }
        }).then(({ data }) => {
            console.log("data", data);
            this.setState({ areas: data })
        }).catch(error => {
            console.log('error al traer areas', error)
        })
    }

    /**
     *
     *
     * @param {*} [{ page, limit, search }=this.state.rubros]
     * @param {*} [{ rubros }=this.state]
     * @memberof FiltrosDrawer
     * @method loadRubros
     * 
     * @description Cargamos los rubros
     */
    loadRubros = selectedOptions => {
        const targetOption = selectedOptions[selectedOptions.length - 1];
        targetOption.loading = true;

        let areas = this.state.areas

        console.log("targetOption.value", targetOption.value);
        return axios.get('/rubros', {
            params: {
                paginate: false,
                area_id: targetOption.value
            }
        })
            .then(({ data }) => {
                targetOption.loading = false;

                let index = areas.findIndex(area => area.value.toString() === targetOption.value.toString())
                let rubros = data.data;

                if (index !== -1 && rubros.length > 0) {
                    let rubros_dictionary = {}
                    let areas_temp = rubros.map(rubro => {
                        rubros_dictionary[rubro._id] = rubro
                        return ({ label: rubro.nombre, value: rubro._id })
                    })

                    areas[index].children = areas_temp
                    this.setState({ areas: [...areas], rubros_dictionary: { ...this.state.rubros_dictionary, ...rubros_dictionary } });

                } else {
                    message.error('Rubros no encontrados')
                }


            })
            .catch(error => {
                console.log(error)
                message.error('Error al cargar los rubros')
            })

    };

    /**
     *
     *
     * @param {*} [{ page, limit, search }=this.state.empresas]
     * @param {*} [{ cuentas }=this.state]
     * @memberof FiltrosDrawer
     * @method getRazonesSociales
     * 
     * @description Obtenemos las Empresas
     */
    getRazonesSociales = ({ page, limit, search } = this.state.empresas, { empresas } = this.state) => {

        empresas.loading = true;
        empresas.page = page;
        empresas.limit = limit;

        this.setState({ empresas })

        axios.get('/empresas', {
            params: {
                page, limit, search
            }
        })
            .then(({ data }) => {

                empresas.data = (page === 1) ? data.data : [...empresas.data, ...data];

                empresas.dictionary = {}
                empresas.data.map(d => empresas.dictionary[d._id] = d)

                empresas.total = data.total
                empresas.pages = data.pages
                empresas.loading = false;

                this.setState({ empresas })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.empresas.loading = false
                    return state
                })
            })
    }

    /**
    *
    *
    * @param {*} [{ page, limit, search }=this.state.proveedores]
    * @param {*} [{ proveedores }=this.state]
    * @memberof FiltrosDrawer
    * @method getProveedores
    * 
    * @description Obtenemos los proveedores
    */
    getProveedores = ({ page, limit, search } = this.state.proveedores, { proveedores } = this.state) => {

        proveedores.loading = true;
        proveedores.page = page;
        proveedores.limit = limit;
        this.setState({ proveedores })

        axios.get('/proveedores/list', {
            params: {
                page, limit, search
            }
        })
            .then(response => {

                proveedores.data = (page === 1) ? response.data.data.itemsList : [...proveedores.data, ...response.data.data.itemsList];


                proveedores.dictionary = {}
                proveedores.data.map(d => proveedores.dictionary[d._id] = d)

                proveedores.total = response.data.data.paginator.itemCount
                proveedores.pages = response.data.data.paginator.pageCount
                proveedores.loading = false;

                this.setState({ proveedores })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.proveedores.loading = false
                    return state
                })
            })
    }

    /**
     *
     *
     * @memberof FiltrosDrawer
     * @method submit
     * 
     * @description Permite obtener el arreglo para poder mostrarlo en la lista de guardados. 
     */
    submit = ({ area_rubro, clientes, cuentas, proyecto_inmueble, inmuebles, proyectos, empresas, fechas, empleados, inversiones, acreedores, proveedores }) => {

        let filtros = []
        // clasificadores, clientes, cuentas, ordenes, ordenes_compras, empresas

        //Metodo para añadir al areglo de filtros.
        let addToFilter = (name, objectName, array) => array.map(element => filtros.push({
            _id: element,//clasificador,
            name: name,
            objectName,

        }))


        if (inmuebles) addToFilter("inmueble_id", "inmuebles", inmuebles)

        if (clientes) addToFilter("cliente_id", "clientes", clientes)
        if (cuentas) addToFilter("cuenta_id", "cuentas", cuentas)
        if (empleados) addToFilter("empleado_id", "empleados", empleados)
        // if (ordenes) addToFilter(["ordenes.orden_id", "ordenes.orden_id"], "ordenes", ordenes)
        if (empresas) addToFilter("empresa_id", "empresas", empresas)
        if (inversiones) addToFilter("inversion_id", "inversiones", inversiones)
        if (acreedores) addToFilter("acreedor_id", "acreedores", acreedores)
        if (proveedores) addToFilter("proveedor_id", "proveedores", proveedores)
        if (proyectos) addToFilter("proyecto_id", "proyectos", proyectos)

        if (area_rubro) {
            for (const ar of area_rubro) {

                if (ar.length === 1) {
                    filtros.push({
                        _id: ar[0],//clasificador,
                        name: "area_id",
                        objectName: "areas",
                        filter: this.state.areas_dictionary[ar[0]]
                    })
                } else {
                    for (let index = 0; index < ar.length; index++) {
                        if (index === 0) continue;
                        const element = ar[index];
                        filtros.push({
                            _id: element,//clasificador,
                            name: "rubro_id",
                            objectName: "rubros",
                            filter: this.state.rubros_dictionary[element]
                        })
                    }
                }
            }
        }
        if (proyecto_inmueble) {
            for (const ar of proyecto_inmueble) {

                if (ar.length === 1) {
                    filtros.push({
                        _id: ar[0],//clasificador,
                        name: "proyecto_id",
                        objectName: "proyectos",
                        filter: this.state.proyectos_dictionary[ar[0]]
                    })
                } else {
                    for (let index = 0; index < ar.length; index++) {
                        if (index === 0) continue;
                        const element = ar[index];
                        filtros.push({
                            _id: element,//clasificador,
                            name: "inmueble_id",
                            objectName: "inmuebles",
                            filter: this.state.inmuebles_dictionary[element]
                        })
                    }
                }
            }
        }
        if (fechas) {
            filtros.push({ fechas: fechas })
        }

        if (this.props.updateFilters)
            this.props.updateFilters(filtros)
    }

    /**
     *
     *
     * @param {*} [{ page, limit, search }=this.state.rubros]
     * @param {*} [{ rubros }=this.state]
     * @memberof FiltrosDrawer
     * @method loadRubros
     * 
     * @description Cargamos los rubros
     */
    loadInmuebles = selectedOptions => {
        const targetOption = selectedOptions[selectedOptions.length - 1];
        targetOption.loading = true;

        let proyectos = this.state.proyectos
        console.log("RUNNING LOAD")
        console.log("targetOption.value", targetOption.value);
        return axios.get('/inmuebles', {
            params: {
                paginate: false,
                proyecto_id: targetOption.value
            }
        })
            .then(({ data }) => {
                targetOption.loading = false;

                let index = proyectos.findIndex(area => area.value.toString() === targetOption.value.toString())
                let inmuebles = data.data;

                if (index !== -1 && inmuebles.length > 0) {
                    let inmuebles_dictionary = {}
                    let proyectos_temp = inmuebles.map(inmueble => {
                        inmuebles_dictionary[inmueble._id] = inmueble
                        return ({ label: inmueble.nombre, value: inmueble._id })
                    })

                    proyectos[index].children = proyectos_temp
                    this.setState({ proyectos: [...proyectos], inmuebles_dictionary: { ...this.state.inmuebles_dictionary, ...inmuebles_dictionary } });

                } else {
                    message.error('Inmuebles no encontrados')
                }


            })
            .catch(error => {
                console.log(error)
                message.error('Error al cargar los rubros')
            })

    };


    /**
     *
     *
     * @memberof FiltrosDrawer
     * @method downloadCSV
     */
    downloadCSV = () => {
        let url = new URL(axios.defaults.baseURL + '/' + this.props.csv)
        url.searchParams.set('csv', true)
        url.searchParams.set('Authorization', sessionStorage.getItem('token'))
        window.open(url, '_blank');
    }

    render() {

        const { onClose, visible } = this.props
        const { clientes, cuentas, empresas, ordenes, ordenes_compras, proveedores, proyectos, proyectos_inmueble } = this.state
        return (<Drawer
            title={this.props.title}
            placement="right"
            onClose={onClose}
            visible={visible}
            extra={
                <Space>
                    {this.props.extra}
                    {(this.props.csv !== undefined) &&
                        <Button
                            onClick={this.downloadCSV}
                            icon={<DownloadOutlined />}
                        >CSV</Button>}
                </Space>
            }>


            <Form
                ref={ref => this.formFiltros = ref}
                layout='vertical'
                onValuesChange={(x, values) => this.submit(values)}
                onFinish={this.submit}
            >
                {/* RangePicker */}
                {(this.props.fechas === undefined || this.props.fechas !== false) ?
                    <Form.Item label="Rango de Fechas" name="fechas" >
                        <RangePicker placeholder={["Fecha Inicial", "Fecha Final"]} />
                    </Form.Item> : null}
                {(this.props.cuentas === undefined || this.props.cuentas !== false) ?
                    <Form.Item label="Cuentas" name="cuentas">
                        <Select
                            className="width-100"
                            allowClear
                            mode="multiple"
                            showSearch
                            filterOption={false}
                            onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !cuentas?.loading && cuentas.page < cuentas.pages) ? this.getCuentas({ page: cuentas.page + 1 }) : null}
                            onSearch={search => this.getCuentas({ search, page: 1 })}
                        >
                            {cuentas.data.map(cuenta => <Option key={cuenta._id} value={cuenta._id}>
                                <CAvatar
                                    size='small'
                                    image={axios.defaults.baseURL + '/upload/' + cuenta.logo}
                                    name={cuenta.nombre}
                                    color={cuenta.color}
                                /> &nbsp;{cuenta.nombre}</Option>)}
                        </Select>
                    </Form.Item> : null}
                {(this.props.proyectos_inmueble === undefined || this.props.proyectos_inmueble !== false) ?
                    <Form.Item label="Proyectos / Inmuebles" name="proyecto_inmueble">
                        <Cascader
                            multiple={true}
                            placeholder="Seleccione el Proyecto e Inmueble"
                            options={proyectos_inmueble}
                            //loadData={this.loadInmuebles}
                            changeOnSelect={true}
                            fieldNames={{
                                label: "nombre",
                                value: "_id",
                            }}
                        />
                    </Form.Item> : null}

                {(this.props.proyectos === undefined || this.props.proyectos !== false) ?
                    <Form.Item label="Proyectos" name="proyectos">
                        <Select
                            className="width-100"
                            allowClear
                            mode="multiple"
                            showSearch
                            placeholder="Seleccione el proyecto"
                            filterOption={false}
                            onChange={(values) => this.setState({proyecto_ids: values})}
                            onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !proyectos?.loading && proyectos.page < proyectos.pages) ? this.getProyectos({ page: proyectos.page + 1 }) : null}
                            onSearch={search => this.getProyectos({ search, page: 1 })}
                        >
                            {proyectos.data.map(proyecto => <Option key={proyecto._id} value={proyecto._id}><Badge color={proyecto.color} />{proyecto.nombre}</Option>)}
                        </Select>
                    </Form.Item> : null}

                {(this.props.inmuebles === undefined || this.props.inmuebles !== false) ?
                    <Form.Item label="Inmuebles" name="inmuebles">
                        <SelectInmueble 
                            mode="multiple"
                            params={{
                                proyecto_ids: this.state.proyecto_ids
                            }}
                        />
                    </Form.Item> : null}


                {(this.props.ordenes === undefined || this.props.ordenes !== false) ?
                    <Form.Item label="Ventas / Ordenes" name="ordenes">
                        <Select
                            className="width-100"
                            allowClear
                            mode="multiple"
                            showSearch
                            filterOption={false}

                            onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !ordenes?.loading && ordenes.page < ordenes.pages) ? this.getOrdenes({ page: ordenes.page + 1 }) : null}
                            onSearch={search => this.getOrdenes({ search, page: 1 })}

                            onSelect={() => this.getOrdenesCompras({ orden_id: this.formFiltros.getFieldValue('ordenes'), page: 1 })}
                        >
                            {ordenes.data.map(orden => <Option key={orden._id} value={orden._id}>{orden.folio}</Option>)}
                        </Select>
                    </Form.Item> : null}
                {(this.props.ordenes_compras === undefined || this.props.ordenes_compras !== false) ?
                    <Form.Item label="Ordenes de Compra" name="ordenes_compras">
                        <Select
                            className="width-100"
                            allowClear
                            mode="multiple"
                            showSearch
                            filterOption={false}

                            onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !ordenes_compras?.loading && ordenes_compras.page < ordenes_compras.pages) ? this.getOrdenesCompras({ page: ordenes_compras.page + 1 }) : null}
                            onSearch={search => this.getOrdenesCompras({ search, page: 1 })}
                        >
                            {ordenes_compras.data.map(orden_compra => <Option key={orden_compra._id} value={orden_compra._id}>{orden_compra.folio}</Option>)}
                        </Select>
                    </Form.Item> : null}
                {(this.props.area_rubro === undefined || this.props.area_rubro !== false) ?
                    <Form.Item label="Áreas / Rubros" name="area_rubro">
                        <Cascader
                            multiple={true}
                            placeholder="Seleccione el Área y Rubro"
                            options={this.state.areas}
                            //loadData={this.loadRubros}
                            changeOnSelect
                            fieldNames={{
                                label: "nombre",
                                value: "_id",
                            }}
                        />
                    </Form.Item> : null}
                {(this.props.empresas === undefined || this.props.empresas !== false) ?
                    <Form.Item label="Empresas" name="empresas">
                        <Select
                            className="width-100"
                            allowClear
                            mode="multiple"
                            showSearch
                            filterOption={false}
                            onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !empresas?.loading && empresas.page < empresas.pages) ? this.getRazonesSociales({ page: empresas.page + 1 }) : null}
                            onSearch={search => this.getRazonesSociales({ search, page: 1 })}
                        >
                            {empresas.data?.map(razon_social => <Option key={razon_social._id} value={razon_social._id}>        <CAvatar
                                size='small'
                                image={razon_social.logo}
                                name={razon_social.razon_social}
                                color={razon_social.color}
                            /> &nbsp; {razon_social.razon_social}</Option>)}
                        </Select>
                    </Form.Item> : null}


                {(this.props.clientes === undefined || this.props.clientes !== false) ?
                    <Form.Item label="Clientes" name="clientes"
                    
                    // extra={<Form.Item noStyle name={""}> 
                    //     <Checkbox onChange={(e) => {

                    //         // console.log('e', e.target);
                    //         this.setState(state => {
                    //             state.clientes.disabled = !state.clientes.disabled
                    //             return state;
                    //         })
                    //     }}>Solo los Clientes</Checkbox>
                    // </Form.Item>}
                    >
                        <Select
                            className="width-100"
                            allowClear
                            mode="multiple"
                            showSearch
                            disabled={clientes.disabled}
                            filterOption={false}
                            onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !clientes?.loading && clientes.page < clientes.pages) ? this.getRazonesSociales({ page: clientes.page + 1 }) : null}
                            onSearch={search => this.getClientes({ search, page: 1 })}

                        >
                            {
                                [
                                    <Option value={false}>Todos los Clientes</Option>,              
                                    ...clientes.data?.map(cliente => <Option key={cliente._id} value={cliente._id}>        <CAvatar
                                        size='small'
                                        image={cliente.logo}
                                        name={cliente.nombre}
                                        color={cliente.color}
                                    /> &nbsp; {cliente.nombre} {cliente.apellido}</Option>)
                                ]
                            }
                        </Select>
                    </Form.Item> : null}


                {(this.props.proveedores === undefined || this.props.proveedores !== false) ?
                    <Form.Item label="Proveedores" name="proveedores">
                        <Select
                            className="width-100"
                            allowClear
                            mode="multiple"
                            showSearch
                            filterOption={false}
                            onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !proveedores?.loading && proveedores.page < proveedores.pages) ? this.getProveedores({ page: proveedores.page + 1 }) : null}
                            onSearch={search => this.getProveedores({ search, page: 1 })}
                        >
                            {proveedores.data.map(proveedor => <Option key={proveedor._id} value={proveedor._id}><Badge color={proveedor.color} />{proveedor.nombre}</Option>)}
                        </Select>
                    </Form.Item> : null}



                {(this.props.inversiones === undefined || this.props.inversiones !== false) ?
                    <Form.Item label="Inversiones" name="inversiones">
                        <SelectInversion
                            className="width-100"
                            allowClear={true}
                            mode="multiple"
                        />
                    </Form.Item> : null}

                {(this.props.acreedores === undefined || this.props.acreedores !== false) ?
                    <Form.Item label="Acreedores" name="acreedores">
                        <SelectAcreedor
                            className="width-100"
                            allowClear={true}
                            mode="multiple"
                        />
                    </Form.Item> : null}

                {(this.props.empleados === undefined || this.props.empleados !== false) ?
                    <Form.Item label="Empleados/Maestros" name="empleados">
                        <Selectempleado
                            className="width-100"
                            allowClear={true}
                            mode="multiple"
                        />
                    </Form.Item> : null}
            </Form>
        </Drawer >)
    }
};
