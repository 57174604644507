import React, { Component } from "react";
import { Row, Col, Button, Popconfirm, Spin, PageHeader, Layout, message, Space, List, Card, Typography, Collapse, Divider, Pagination, } from "antd";
import axios from "axios";
import { PlusOutlined, CloseCircleFilled, WarningFilled, CheckCircleFilled } from "@ant-design/icons";

//Componentes
import Logged from "../../../Hooks/Logged"
import { tipo_servicio } from "../../Utils";
import usePermissions from "../../../Hooks/usePermissions";
import { IconDelete, IconEdit } from "../../Widgets/Iconos";
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import { CardInfoDashboard, CardProveedor, CardFacturas } from '../../Widgets/Cards';
//modelos
import ModalProveedores from "./ModalProveedores";


const { Content } = Layout;
const { Text, Title } = Typography;
const { Panel } = Collapse;

/**
 * @export
 * @class Proveedores
 * @extends {Component}
 * @description Vista de Proveedores
 */
class Proveedores extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            modalVisible: false,

            proveedor_id: undefined,

            proveedores: {
                data: [],
                limit: 100,
                page: 1,
                total: 0,
                pages: 0,
                ultimas: [],
                general: {},
            },
            facturas: [],
        };
    }

    componentDidMount() {
        axios.defaults.headers.common["Authorization"] = sessionStorage.getItem("token");
        this.getProveedores();
    }

    /**
     *
     * @memberof Cuentas
     * @method componentDidUpdate
     * @description Si se realiza una busqueda, filtra las Cuentas
     */
    componentDidUpdate(prevProps) {
        if (this.props.search !== prevProps.search)
            this.getProveedores()
    }

    /**
     *
     *
     * @memberof Proveedores
     *
     * @method getProveedores
     * @description Obitiene los Proveedores
     */
    getProveedores = (
        {
            page = this.state.proveedores.page,
            limit = this.state.proveedores.limit,
            search = this.props.search,
        } = this.state.proveedores
    ) => {
        this.setState({ loading: true });
        axios
            .get("/proveedores", {
                params: {
                    page: page,
                    limit: limit,
                    search: search,
                    facturas: true
                },
            })
            .then((response) => {
                this.setState({ proveedores: response.data });
            })
            .catch((error) => {
                console.log(error);
                message.error("Error al traer la información");
            })
            .finally(() => this.setState({ loading: false }));
    };

    deleteProveedores = (item) => {

        axios
            .delete(
                `/proveedores/${item._id}`,
                {
                    params: {
                        id: item._id,
                    },
                }
            )
            .then(
                () => {
                    this.getProveedores();
                    message.success(
                        "Proveedor eliminado"
                    );
                }
            )
            .catch(
                (
                    error
                ) =>
                    console.log(
                        error
                    )
            )
    }

    render() {

        let { proveedores } = this.state

        return (
            <>
                <PageHeader
                    className="site-page-header custom-page-header"
                    title="Proveedores"
                />

                <Spin spinning={this.state.loading}>
                    <Content className="admin-content content-bg pd-1">
                        <Row gutter={[8, 8]}>
                            <Col xs={24} lg={8} className="grid">
                                <CardInfoDashboard
                                    title='Saldo total proveedores'
                                    monto={<>$ {proveedores.general?.saldo_total ? proveedores.general?.saldo_total.toMoney(true) : 0} <small>MXN</small></>}
                                />
                            </Col>
                            <Col xs={24} lg={8} className="grid">
                                <CardInfoDashboard
                                    title='Saldo por vencer'
                                    monto={<>$ {proveedores.general?.saldo_por_vencer ? proveedores.general?.saldo_por_vencer.toMoney(true) : 0} <small>MXN</small></>}
                                />
                            </Col>
                            <Col xs={24} lg={8} className="grid">
                                <Card>
                                    <Title level={3}>  Resumen Facturas</Title>
                                    <Row>
                                        <Col span={8}><Text className="flex-between" style={{ width: '100%', color: '#FF4D4F', paddingRight: '1rem' }}><CloseCircleFilled />Vencidas</Text></Col>
                                        <Col span={12} className="center">{proveedores?.general?.vencidas}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={8}><Text className="flex-between" style={{ width: '100%', color: '#FAAD14' }}><WarningFilled />Por Vencer</Text></Col>
                                        <Col span={12} className="center">{proveedores?.general?.por_vencer}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={8}><Text className="flex-between" style={{ width: '100%', color: '#52C41A' }}><CheckCircleFilled />Liquidadas</Text></Col>
                                        <Col span={12} className="center">{proveedores?.general?.liquidadas}</Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        <Row gutter={[16, 24]} className="mt-1">
                            <Col xs={24} lg={16}>
                                <Row>
                                    <Col span={24}>
                                        <Title level={3} className="sub-section-title">Proveedores</Title>
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]} className="scroll-proveedores" style={{ paddingTop: '8px', maxHeight: '700px', overflow: "hidden", overflowY: "auto" }}>
                                    {
                                        this.state.proveedores.data.map(prov => <Col span={12}>
                                            <CardProveedor {...prov} />
                                        </Col>)
                                    }
                                </Row>
                                <Row gutter={[16, 16]} className="scroll-proveedores" style={{ paddingTop: '8px', maxHeight: '700px', overflow: "hidden", overflowY: "auto" }}>
                                    <Pagination
                                        current={this.state.proveedores.page}
                                        pageSize={this.state.proveedores.limit}
                                        showSizeChanger
                                        total={this.state.proveedores.total}
                                        onChange={(page, limit) => this.getProveedores({ page, limit })}
                                    />
                                </Row>
                            </Col>
                            <Col xs={24} lg={8}>
                                <Row>
                                    <Col span={24} className="flex-between">
                                        <Title level={3} className="sub-section-title">Facturas</Title>
                                    </Col>
                                </Row>
                                <Row className="scroll-proveedores" style={{ paddingTop: '8px', maxHeight: '700px', overflow: "hidden", overflowY: "auto" }}>
                                    {
                                        this.state.proveedores?.ultimas?.map(fact => {
                                            return <Col span={24} className="mb-1">
                                                <CardFacturas {...fact} onClick={() => {
                                                    this.setState({ modalDetalleVisible: true, factura: fact })
                                                }} />
                                            </Col>
                                        })
                                    }
                                </Row>
                            </Col>

                        </Row>

                    </Content>

                    {this.props.createProveedores ? <FloatingButton
                        title="Nuevo registro"
                        onClick={() => this.setState({ modalVisible: true })}
                    /> : null}

                    <ModalProveedores
                        visible={this.state.modalVisible}
                        onClose={() => {
                            this.setState({
                                modalVisible: false,
                                proveedor_id: undefined,
                            });
                            this.getProveedores();
                        }}
                        proveedor_id={this.state.proveedor_id}
                    />
                    {/* <ModalServicios
                        visible={this.state.modal_visible}
                        onCancel={() => {
                            this.setState({
                                modal_visible: false,
                                proveedor_id: undefined,
                                servicio_id: undefined,
                            });
                            this.getProveedores();
                        }}
                        proveedor_id={this.state.proveedor_id}
                        servicio_id={this.state.servicio_id}
                    /> */}
                </Spin>
            </>
        );
    }
}

export default function (props) {


    let user = React.useContext(Logged)


    let permissions = usePermissions(user?.rol_id?.permisos, {
        editProveedores: ["proveedores", "edit"],
        createProveedores: ["proveedores", "create"],
        deleteProveedores: ["proveedores", "delete"],

    })


    return <Proveedores {...props} {...permissions} />
}