import React, { Component } from 'react';
import { Row, Col, Card, Button, Popconfirm, Spin, PageHeader, Layout, message, Space, List, Typography, Collapse, Dropdown, Menu, Upload, Switch, Modal, Tag,  Popover, Empty } from 'antd'
import axios from 'axios';
import InfiniteScroll from "react-infinite-scroll-component";
import { EllipsisOutlined, DeleteOutlined } from '@ant-design/icons';
//componentes
import CustomAvatar from '../../../Widgets/Avatar/Avatar';
import FormRequisicionOpcionAceptada from "./FormRequisicionOpcionAceptada"
import { IconCheckMedalColor, IconPlusGroup, IconFlag } from '../../../Widgets/Iconos'


//Modal
import ModalCotizacion from "../ModalCotizacion"

const { Text } = Typography;
const moment = require('moment')

const { Content } = Layout;
const { Panel } = Collapse;


let colors_flag = {
    0: '#5151ec',
    1: '#ecea51',
    2: '#ec5151',
}

/**
 * @export
 * @class ListaRequisicion
 * @extends {Component}
 * @description Vista de ListaRequisicion
 */
export default class ListaRequisicion extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            requisiciones:{
                data: [],
                page: 1,
                limit: 20,
                total: 0,
                called: false
            },
            modal_visible: false,
            proyecto_id: undefined,

        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getRequisiciones()
    }

    componentDidUpdate(prevProps) {
        if(prevProps.search != this.props.search)
            this.getRequisiciones({page: 1})

    }

    /**
     * @methodOf ModalRequisiciones
     * @function getRequisiciones
     * @description Otiene el listado de requeridos
     */
    getRequisiciones = ({
        page = this.state.requisiciones.page,
        limit = this.state.requisiciones.limit,
        search = this.props.search,
    } = this.state.requisiciones) => {

        this.setState({loading: true})
        axios.get("/requisiciones",{
            params:{
                page,
                limit,
                search,
                estatus: this.props.estatus
            }
        }).then(({data}) => {
            let requisiciones = page === 1 ? [] : [...this.state.requisiciones.data]

            this.setState({
                requisiciones: {
                    data: requisiciones.concat(data.data),
                    page: data.page,
                    total: data.total,
                    limit: data.limit,
                    called: true
                },
                hasMore: requisiciones.concat(data.data).length < data.total
            })

        }).catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al obtener las requisiciones")

        }).finally(()=>this.setState({loading: false}))

    }

    next = () => {
        console.log("next");
        if(this.state.requisiciones.data.length < this.state.requisiciones.total && this.state.loading === false){
            return this.getRequisiciones({page: this.state.requisiciones.page + 1})
        }
    }
    

    /***
     *
     * @methodOf ListaRequisicion
     * @function activate
     * @description Actualiza el marcador de activacion de la requisicion
     * 
     * @params requisicion_id id de la requisisicion a actualizar
     */
    activate = (requisicion_id, index) => {
        axios.put('/requisiciones',{
            requisicion_id,
            activado: true
        }).then(response => {
            let data = this.state.requisiciones.data
            data[index].activado = response.data.activado
            data[index].fecha_autorizacion = response.data.fecha_autorizacion
            this.setState({
                requisiciones:{
                    ...this.state.requisiciones,
                    data
                }
            })
        }).catch(error => {
            message.error(error?.response?.data?.message ?? "Error al actualizar")
        })
    }


     /***
     *
     * @methodOf ListaRequisicion
     * @function updateEstatus
     * @description Actualiza el marcador de activacion de la requisicion
     * 
     * @params requisicion_id id de la requisisicion a actualizar
     */
    updateEstatus = (requisicion_id, estatus) => {
        axios.put('/requisiciones',{
            requisicion_id,
            estatus
        }).then(response => {
            this.props.getRequisiciones([1,2,3,0])
            message.success("Estatus Actualizado")
        }).catch(error => {
            message.error(error?.response?.data.message ?? "Error al actualizar")
        })
    }

    /**
     * @methodOf ModalRequisiciones
     * @function updateRequisicionOpcion
     * @description Actualiza la informacion de una requisiscion opcion
     */
    updateRequisicionOpcion = (values) => {
        axios.put('/requisicion-opcion',{
            ...values
        }).then(response => {
            console.log("Actualizado")
        }).catch(error => {
            console.log("No Actualizado ")
        })
    }







    render() {

        let { label, name, estatus } = this.props

        return (
            <>
                <Collapse
                    bordered={false}
                    className="mb-1 w-100"
                    defaultActiveKey={[this.props.estatus]}
                >
                    <Panel
                        header={this.props.label}
                        className={`requisiciones-tab tab-${this.props.name}`}
                        key={this.props.estatus}
                    >
                        { this.state.requisiciones.data.length > 0 ? <InfiniteScroll
                            dataLength={this.state.requisiciones.data.length}
                            next={this.next}
                            hasMore={this.state.hasMore}
                            loader={<Spin tip="Cargando..."><h4 style={{height: '100px'}}></h4></Spin>}
                            height={550}
                            className="mb-2"
                        >
                            <List
                                loading={this.state.loading}
                                className="component-list"
                                itemLayout="horizontal"
                                locale={{ emptyText: this.state.loading ? "Cargando" : "Sin Requisiciones" }}
                                dataSource={this.state.requisiciones.data}
                                renderItem={({_id, producto_id, proyecto_id, volumen, prioridad, opciones, categoria_id, activado, createdAt, unidad_id, inmuebles, fecha_autorizacion}, index) => (
                                    <List.Item className="component-list-item" key={_id}>
                                        <Collapse className='width-100'>
                                            <Panel
                                                header={
                                                    <Row className="width-100 " gutter={[0, 12]}>
                                                        <Col xs={24} md={5} className="center" >
                                                            <Text ellipsis>{producto_id?.nombre}</Text>
                                                        </Col>
                                                        <Col xs={24} md={3} className="center" >
                                                            <Text ellipsis >{categoria_id.nombre}</Text>
                                                        </Col>
                                                        <Col xs={24} md={3} className="center" >
                                                            <Text ellipsis >{volumen?.toMoney(true)} {unidad_id?.nombre}</Text>  
                                                        </Col>
                                                        <Col xs={24} md={3} className="center" >
                                                            <Popover 
                                                                content={inmuebles?.map(e=><><Text>{e.nombre}</Text><br/></>)} 
                                                            >
                                                                <Text ellipsis>{inmuebles?.map(e=><Tag color="lime">{e.nombre}</Tag>)}</Text>
                                                            </Popover>
                                                        </Col>
                                                        <Col xs={24} md={4} className="center" >
                                                            <CustomAvatar 
                                                                name={proyecto_id.nombre}
                                                                color={proyecto_id.color}
                                                                url={{
                                                                    url:`${axios.defaults.baseURL}/proyectos/logo/${proyecto_id?._id}`,
                                                                    name: proyecto_id?.logo?.name
                                                                }}
                                                            />
                                                            <Text>{proyecto_id.nombre}</Text>
                                                        </Col>
                                                        <Col xs={24} md={1} className="center" >
                                                            <IconFlag fill={colors_flag[prioridad] ? colors_flag[prioridad] : '#A4A4A4'} />
                                                        </Col>
                                                        <Col xs={24} md={3} className="center" >
                                                            <Text ellipsis >{ fecha_autorizacion ? moment(fecha_autorizacion).format("YYYY-MM-DD") : activado ? moment(createdAt).format("YYYY-MM-DD")  : "-" }</Text>
                                                        </Col>
                                                        <Col xs={24} md={1} className="center">
                                                            <Button
                                                                className={`btn-permission ${activado ? 'active' : ''}`}
                                                                type="ghost"
                                                                icon={<IconCheckMedalColor/>}
                                                                onClick={(e)=>{
                                                                    e.stopPropagation()
                                                                    this.activate(_id, index)
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col xs={24} md={1} className="center">
                                                            <Dropdown
                                                                overlay={<Menu onClick={({ key }) => {
                                                                    if(key !== '4')
                                                                        this.updateEstatus(_id, key)
                                                                }}>
                                                                    <Menu.Item key="1">
                                                                        Requerir
                                                                    </Menu.Item>
                                                                    {estatus === 2 ? null : <Menu.Item key="2">
                                                                        Cotizar
                                                                    </Menu.Item>}
                                                                    {estatus === 3 ? null : <Menu.Item key="3">
                                                                        Pausar
                                                                    </Menu.Item>}
                                                                    {estatus === 0 ? null : <Menu.Item key="0">
                                                                        Cancelar
                                                                    </Menu.Item>}
                                                                    <Menu.Item key="4" icon={<DeleteOutlined  />} 
                                                                        onClick={() => Modal.confirm({
                                                                            title: "¿Deseas eliminar esta requisición?",
                                                                            onOk: () => axios.delete('/requisiciones', { params: { requisicion_id: _id } })
                                                                                .then(({ data }) => {
                                                                                    message.success("Se eliminó correctamente")
                                                                                    this.getRequisiciones([this.props.estatus])
                                                                                })
                                                                                .catch(({ data }) => {
                                                                                    message.error("No se pudo eliminar")
                                                                                })
                                                                        })}>
                                                                            Eliminar
                                                                        </Menu.Item>
                                                                    </Menu>}
                                                                placement="bottomRight"
                                                                arrow
                                                                onClick={(e) => {
                                                                    e.stopPropagation()
                                                                }}
                                                            >
                                                                <Button type="ghost" icon={<EllipsisOutlined rotate={90}/>} />
                                                            </Dropdown>
                                                        </Col>
                                                    </Row>
                                                }
                                                key={_id}
                                                className={`requisiciones-item-${name}`}
                                            >
                                                <List
                                                    className='component-list'
                                                    itemLayout="horizontal"
                                                    locale={{ emptyText: "Sin proveedores" }}
                                                    dataSource={opciones}
                                                    header={
                                                        <Row className="width-100 ">
                                                            <Col span={1} className="center" >
                                                                
                                                            </Col>
                                                            <Col span={5} className="center" >
                                                                <Text className="text-gray" ellipsis>Proveedor</Text>
                                                            </Col>
                                                            <Col span={3} className="center" >
                                                                <Text className="text-gray" ellipsis >Precio Unitario</Text>
                                                            </Col>
                                                            <Col span={3} className="center" >
                                                                <Text className="text-gray" ellipsis >Dias de entrega</Text>
                                                            </Col>
                                                            <Col span={3} className="center" >
                                                                <Text className="text-gray" ellipsis >Volumen Final</Text>
                                                            </Col>
                                                            <Col span={3} className="center" >
                                                                <Text className="text-gray" ellipsis >Monto</Text>
                                                            </Col>
                                                            <Col span={4} className="center" >
                                                                <Text className="text-gray" ellipsis >Archivos</Text>
                                                            </Col>
                                                        </Row>
                                                    }
                                                    renderItem={item => (
                                                        <List.Item>
                                                            <FormRequisicionOpcionAceptada 
                                                                {...item}
                                                                volumen={volumen}
                                                                update={this.updateRequisicionOpcion}
                                                                openModalCotizacion={()=>this.setState({
                                                                    modal_visible: true, 
                                                                    proyecto_id: proyecto_id._id, 
                                                                    requisicion_opcion_id: item._id
                                                                })}
                                                            />
                                                        </List.Item>
                                                    )}
                                                />
                                            </Panel>
                                        </Collapse>
                                    </List.Item>
                                )}
                            />
                        </InfiniteScroll> : <Empty description={"Sin Requisisciones"} className="mb-3 mt-1"/> }

                    </Panel>
                </Collapse>
                <ModalCotizacion
                    visible={this.state.modal_visible}
                    onClose={(flag)=>{
                        this.setState({modal_visible: false, proyecto_id: undefined})
                        if(flag === true){
                            this.props.getRequisiciones([this.props.estatus, 4,5])
                        }
                    }}
                    proyecto_id={this.state.proyecto_id} //para cargar los almacenes del proyecto
                    requisicion_opcion_id={this.state.requisicion_opcion_id}
                />
            </>
        )
    }
}
