import React, { Component, useContext } from "react";
import { Button, Col, Row, Tag, Card, List, Space, message, Popconfirm, Modal, Tooltip } from "antd";
import axios from 'axios'
import { DeleteOutlined, CheckCircleOutlined, EditOutlined, } from "@ant-design/icons"


import User from "../../../../Hooks/Logged";
import usePermissions from "../../../../Hooks/usePermissions";

import ModalTransaccionDetalle from "../../Finanzas/Transacciones/ModalTransaccionDetalle";
import ModalTransaccionProgramada from "../../Finanzas/Transacciones/ModalTransaccionProgramada";


const moment = require('moment');

/**
 * @export
 * @class TransaccionesProgramadasTab
 * @extends {Component}
 * @description Lista de transacciones del cliente
 */
class TransaccionesProgramadasTab extends Component {

	constructor(props) {
		super(props)
		this.state = {
			transacciones: {
				data: [],
				limit: 20,
				page: 1,
				total: 0,
				pages: 0,
			},
			proveedor_id: this.props.proveedor_id,
			transaccion_id: undefined
		}
	}

	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
		if (this.props.proveedor_id) {
			this.getTransacciones()
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.proveedor_id && prevProps.proveedor_id !== this.props.proveedor_id) {
			this.getTransacciones()
		}
	}

	/**
	 * @memberof TransaccionesProgramadasTab
	 * @method getTransacciones
	 * @description Obtiene los transacciones realcionadas al proveedor
	 *
	 */
	getTransacciones = ({
		page = this.state.transacciones.page,
		limit = this.state.transacciones.limit,
		proveedor_id = this.props.proveedor_id,
	} = this.state.transacciones) => {

		this.setState({ loading: true })
		axios.get('/transacciones-programadas', {
			params: {
				proveedor_id,
				page,
				limit
			}
		})
			.then(({ data }) => {
				this.setState({
					transacciones: {
						...data
					}
				});
			})
			.catch(error => {
				message.error(error.response.data.message + "(transacciones programadas)" ?? 'Error al traer las transacciones programadas')
				console.log(error.response)
			}
			).finally(() => this.setState({ loading: false }))
	}



	diasAtraso = (item) => {
		if (item.monto_pagado == item.monto)
			return

		const fecha_limite = moment(item.fecha_limite)


		//Si la fecha actual esta dsepues de la fecha limite
		if (moment().isAfter(fecha_limite))
			return <div style={{ position: "absolute", right: '-100%', top: 0, color: "#F50" }}>
				(-{moment().diff(fecha_limite, 'day')})
			</div>

		if (!fecha_limite.subtract(item.dias_limite, 'days').isAfter(moment()))
			return <div style={{ position: "absolute", right: '-100%', top: 0, color: "#FBBC30" }}>
				({moment().diff(fecha_limite, 'day')})
			</div>
	}

	renderEstatus = (item) => {
		if (item.monto_pagado == item.monto)
			return <Tag color="#87d068" icon={<CheckCircleOutlined />}><strong>PAGADO</strong></Tag>

		const fecha_limite = moment(item.fecha_limite)

		//Si la fecha actual esta despues de la fecha limite
		if (moment().isAfter(fecha_limite))
			return <Tag color="#F50">Vencida</Tag>

		if (fecha_limite.subtract(item.dias_limite, 'days').isAfter(moment()))
			return <Tag color="#2db7f5">Pendiente</Tag>

		return <Tag color="#FBBC30">Proxima a pagar</Tag>
	}

	render() {

		const { data } = this.state.transacciones

		return (
			<>
				<Row gutter={[8, 8]}>
					<List
						itemLayout="horizontal"
						dataSource={data}
						loading={this.state.loading}
						key={(item => item._id)}
						className="component-list width-100"
						pagination={{
							current: this.state.transacciones.page,
							pageSize: this.state.transacciones.limit,
							total: this.state.transacciones.total,
							showSizeChanger: true,
							position: 'bottom',
							className: "flex-left",
							onChange: (page, limit) => this.getTransacciones({ page, limit })
						}}
						header={<Row className="width-100" align={"middle"} gutter={[16, 16]}>
							<Col xs={12} md={3} className="center">
								<strong>CONCEPTO</strong>
							</Col>
							<Col xs={12} md={3} className="center">
								<strong>FECHA DE CREACIÓN</strong>
							</Col>
							<Col xs={12} md={3} className="center">
								<strong>FECHA DE PAGO</strong>
							</Col>
							<Col xs={12} md={4} className="center">
								<strong>MONTO</strong>
							</Col>
							<Col xs={12} md={4} className="center">
								<strong>MONTO PAGADO</strong>
							</Col>
							<Col xs={12} md={4} className="center">
								<strong>MONTO PENDIENTE</strong>
							</Col>
							<Col xs={12} md={1} className="center">
								<strong>ESTATUS</strong>
							</Col>
							<Col xs={24} md={2} align="right"></Col>
						</Row>}
						renderItem={item => (
							<List.Item className="component-list-item transacciones">
								<Card className="ant-card-list width-100" style={{ position: "relative" }}>
									{(item.tipo == 1) ?
										<div style={{ height: "100%", background: "#87d068", width: 7, borderRadius: "10px 0px 0 10px ", position: "absolute", left: 0, top: 0 }} />
										:
										<div style={{ height: "100%", background: "#ff4d4f", width: 7, borderRadius: "10px 0px 0 10px ", position: "absolute", left: 0, top: 0 }} />
									}
									<Row className="width-100" align={"middle"} gutter={[16, 16]}>
										<Col xs={12} md={3} className="center">
											{item.concepto}
										</Col>
										<Col xs={12} md={3} className="center">
											{moment(item.createdAt).format('YYYY-MM-DD')}
										</Col>
										<Col xs={12} md={3} className="center">
											<span style={{ position: "relative" }}>
												{this.diasAtraso(item)}
												{moment(item.fecha_limite).format('YYYY-MM-DD')}
											</span>
										</Col>
										<Col xs={12} md={4} className="center">
											{item.monto.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })}
										</Col>
										<Col xs={12} md={4} className="center">
											{item.monto_pagado.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })}
										</Col>
										<Col xs={12} md={4} className="center">
											{item.monto_pendiente.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })}
										</Col>
										<Col xs={12} md={1} className="center">
											{this.renderEstatus(item)}
										</Col>
										<Col xs={24} md={2} align="right">
											<Space direction="horizontal" size={5}>
												<Tooltip mouseEnterDelay={0} title="Editar la transacción programada" placement="rightTop">
													<Button
														size="small"
														type="primary"
														disabled={!this.props.editarTransaccionesProgramadas}
														icon={<EditOutlined style={{ color: "currentcolor" }} />}
														onClick={() => this.setState({ modalVisible: true, transaccion_programada_id: item._id })}
													/>
												</Tooltip>
												<Popconfirm
													placement="topRight"
													title="¿Deseas eliminar esta transacción programada?"
													onConfirm={() => axios.delete(`/transacciones-programadas`,
														{ params: { id: item._id } }).then(() => {
															this.get()
															message.success('Transacción programada eliminada')
															this.props.updateAll()
															this.getTransacciones()
														}).catch(error => {
															if (Array.isArray(error.response.data)) {
																Modal.error({
																	title: "Hay transacciones relacionadas con esta transacción programada",
																	content: <List
																		size="small"
																		bordered={false}
																		dataSource={error.response.data}
																		renderItem={(item) => <List.Item
																		>
																			<List.Item.Meta
																				title={item.concepto}
																				description={
																					<>
																						Monto: {item.monto?.toMoney()} <br />
																						Fecha de Creación: {moment(item.createdAt).format("LLL")}
																					</>
																				}
																			/>
																		</List.Item>}
																	/>
																})


															} else
																message.delete(error.response)
														})}
													okText="Si"
													cancelText="No"
												>
													<Button
														size="small"
														type="primary"
														disabled={!this.props.eliminarTransaccionesProgramadas}
														icon={<DeleteOutlined style={{ color: "currentcolor" }} />}
														title="Eliminar"
														danger
													/>
												</Popconfirm>
											</Space>
										</Col>
									</Row>
								</Card>
							</List.Item>
						)}
					/>
				</Row>

				{/*Modal solo para editar*/}
				<ModalTransaccionProgramada
					visible={this.state.modalVisible}
					onClose={() => {
						this.setState({ modalVisible: false, transaccion_programada_id: undefined })
						this.props.updateAll()
					}}
					transaccion_programada_id={this.state.transaccion_programada_id}
					proveedor_id={this.props.proveedor_id}
					area={false}
					rubro={false}
					cliente={false}
					propiedad={false}
					factura={null}
					proyecto={false}
					tipo={false}
					inversion={false}
					acreedor={false}
					transaccion_programada={false}
				/>
				<ModalTransaccionDetalle
					visible={this.state.modalDetalleVisible}
					onClose={() => this.setState({ modalDetalleVisible: false, transaccion_id: undefined })}
					transaccion_id={this.state.transaccion_id}
				/>
			</>
		)
	}
}

export default React.forwardRef((props, ref) => {

	const user = useContext(User);

	const permisos = usePermissions(user?.rol_id?.permisos, {

		desvincularTransaccionesInversiones: ["proveedores", "transacciones-programadas", "unlink"],
		editarTransaccionesProgramadas: ["proveedores", "transacciones-programadas", "edit"],
		eliminarTransaccionesProgramadas: ["proveedores", "transacciones-programadas", "delete"]
	});

	return <TransaccionesProgramadasTab ref={ref} {...props} user={user} {...permisos} />
})